import type { CommonResponse, CommonParams } from 'types/common';
import type {
  Registration,
  RegistrationDetails,
} from 'types/crm/registrations';
import HttpClient from 'utils/HttpClient';

// Get list company register
interface GetRegistrationsResponse extends CommonResponse<Registration[]> {
  totalRows: number;
}
const getRegistrations = (params: CommonParams) => {
  return HttpClient.post<typeof params, GetRegistrationsResponse>(
    '/admin/crm/Company/ListCompanyRegister',
    params
  );
};

// Update registration status
interface UpdateRegistrationStatus {
  id: number;
  statusId: number;
}
const updateRegistrationStatus = (params: UpdateRegistrationStatus) => {
  return HttpClient.post<UpdateRegistrationStatus, CommonResponse>(
    '/admin/crm/Company/UpdateCompanyRegistrationStatus',
    params
  );
};

// Get registration details
const getRegistrationDetails = (Id: string) => {
  return HttpClient.get<string, CommonResponse<RegistrationDetails>>(
    '/admin/crm/Company/GetCompanyRegistrationById',
    { params: { Id } }
  );
};

// Update registration
interface UpdateRegistrationParams {
  id: number;
  companyName: string;
  streetName: string;
  streetNumber: string | null;
  firstName: string;
  lastName: string;
  cityCode: string;
  cityName: string;
  marketSegmentIds: number[];
  countryId: number;
  email: string;
  companyEmail: string | null;
  salutation: string;
  companyPhoneNumberCountryCode: string;
  companyPhoneNumber: string;
  businessPhoneNumberCountryCode: string;
  businessPhoneNumber: string;
  mobilePhoneNumberCountryCode: string | null;
  mobilePhoneNumber: string | null;
  languageId: number;
  website: string | null;
  jobTitle: string | null;
  image: string | null;
  avatarAssociateContact: string | null;
  uidNumber: string | null;
  companyRoleIds: number[];
  longitude: number | null;
  latitude: number | null;
}
const updateRegistration = (params: UpdateRegistrationParams) => {
  return HttpClient.post<typeof params, CommonResponse>(
    '/admin/crm/Company/UpdateCompanyRegister',
    params
  );
};

// Get notification number
const getNotificationNumber = () => {
  return HttpClient.get<null, CommonResponse<{ newRegistration: number }>>(
    '/admin/crm/Company/GetNotificationNumber'
  );
};

export {
  getRegistrations,
  updateRegistrationStatus,
  getRegistrationDetails,
  updateRegistration,
  getNotificationNumber,
};
