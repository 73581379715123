import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import useThemeSettings from 'hooks/useThemeSettings';
import useUser from 'hooks/useUser';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguagesByCountry } from 'services/shared/common';
import type { LanguageCode } from 'types/common';
import type { MouseEventCurrying } from 'types/react';
import TypedObject from 'utils/TypedObject';

const languages = {
  en: '/static/flags/flag_en.svg',
  de: '/static/flags/flag_de.svg',
  fr: '/static/flags/flag_fr.svg',
  it: '/static/flags/flag_it.svg',
};
interface LanguageItem {
  icon: string;
  label: string;
}

const LanguageSwitcher = () => {
  const { t } = useTranslation();
  const {
    company: { countryId },
  } = useUser();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const [settings, setThemeSettings] = useThemeSettings();
  const [languageOptions, setLanguageOptions] = useState<Record<
    LanguageCode,
    LanguageItem
  > | null>(null);
  const ref = useRef<HTMLButtonElement | null>(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getLanguagesByCountry(countryId)
      .then((response) => {
        const { data } = response;
        const options = (data || []).reduce(
          (acc: Record<string, LanguageItem>, value) => {
            const { languageCode } = value;
            acc[languageCode] = {
              icon: `/static/flags/flag_${languageCode}.svg`,
              label: `languages.${languageCode}`,
            };
            return acc;
          },
          {}
        );
        setLanguageOptions(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [countryId]);

  const handleChangeLanguage: MouseEventCurrying<HTMLLIElement, LanguageCode> =
    (language) => async () => {
      setThemeSettings({ language });
      i18n.changeLanguage(language);
      setOpenDialog(false);

      // run underground
      // await updateUserLanguage(language);
    };

  if (!languageOptions || !settings.language) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton onClick={handleOpenDialog} ref={ref} sx={{ ml: 1 }}>
        <Box
          sx={{
            display: 'flex',
            height: 15,
            width: 20,
            '& > img': { width: 1 },
          }}
        >
          <img alt="Flag" src={languages[settings.language]} />
        </Box>
      </IconButton>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        onClose={handleCloseDialog}
        open={openDialog}
        PaperProps={{ sx: { width: 200 } }}
      >
        <MenuList sx={{ p: 1 }}>
          {TypedObject.keys(languageOptions).map((language) => (
            <MenuItem
              key={language}
              onClick={handleChangeLanguage(language)}
              sx={{ '& + &': { mt: 0.5 }, px: 1 }}
              selected={settings.language === language}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    display: 'flex',
                    height: 15,
                    width: 20,
                    '& > img': { width: 1 },
                  }}
                >
                  <img
                    alt={t(languageOptions[language].label)}
                    src={languageOptions[language].icon}
                  />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle2">
                    {t(languageOptions[language].label)}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </Box>
  );
};

export default LanguageSwitcher;
