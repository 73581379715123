import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Pagination,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import RatingQuestion from './RatingQuestion';
import { styled } from '@mui/system';

import {
  apiPostShipperRateCarrier,
  apiPostUserRateTranspora,
} from '../services';
import {
  IRatingList,
  IShipperRateCarrierParams,
  IUserRateTransporaParams,
} from '../utils/types';
import useNotification from 'hooks/useNotification';

type TProps = {
  totalRating: number;
  ratingList: IRatingList[];
  currentRating: IRatingList;
  setCurrentRating: Function;
  setTotalRating: Function;
  setRatingList: Function;
  onSkip: Function;
};

const getRatingContent = (t: Function, type: string) => {
  if (type === 'null') return;
  return {
    title: {
      mainTitle: t(`rating.${type}.title.mainTitle`),
      subtitle: t(`rating.${type}.title.subtitle`),
    },
    questions: [
      {
        topic: t(`rating.${type}.question1.topic`),
        question: t(`rating.${type}.question1.question`),
      },
      {
        topic: t(`rating.${type}.question2.topic`),
        question: t(`rating.${type}.question2.question`),
      },
      {
        topic: t(`rating.${type}.question3.topic`),
        question: t(`rating.${type}.question3.question`),
      },
    ],
  } as const;
};

const RatingFormWrapper = ({
  totalRating,
  ratingList,
  currentRating,
  setCurrentRating,
  setTotalRating,
  setRatingList,
  onSkip,
}: TProps) => {
  const [page, setPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [starRating, setStarRating] = React.useState<number[]>([0, 0, 0]);
  const [recommends, setRecommends] = React.useState<string[]>(['', '', '']);

  const { t } = useTranslation();
  const setNotification = useNotification();

  const ratingContent = getRatingContent(t, currentRating.type);

  useEffect(() => {
    if (totalRating > 0 && ratingList && currentRating) {
      setIsOpen(true);
    }
  }, [totalRating, ratingList, currentRating]);

  const handleClose = () => {
    setIsOpen(false);
    onSkip();
  };

  const handleNextPage = () => {
    if (totalRating === 1) {
      handleClose();
      return;
    }

    const newRatingList = ratingList.filter(
      (rating) => rating.carrierId !== currentRating.carrierId
    );

    setStarRating([0, 0, 0]);
    setRecommends(['', '', '']);
    setRatingList(newRatingList);
    setTotalRating(newRatingList.length);

    if (page > newRatingList.length) {
      setCurrentRating(newRatingList[page - 2]);
    } else {
      setCurrentRating(newRatingList[page - 1]);
    }
  };

  const checkConditionSubmit = () => {
    const isHasStarZero = starRating.some((star) => star === 0);

    if (isHasStarZero) {
      setNotification({
        error: t('Missed rating!'),
      });
      return false;
    }

    const isNoRecommend =
      !isHasStarZero &&
      recommends.some(
        (recommend, index) => !recommend && starRating[index] < 3
      );

    if (isNoRecommend) {
      setNotification({
        error: t('Missed recommendation!'),
      });
      return false;
    }

    return true;
  };

  const handleSubmitRating = () => {
    if (!checkConditionSubmit()) return;
    if (currentRating.type === 'transpora') {
      const params: IUserRateTransporaParams = {
        isSkip: false,
        usability: {
          rate: starRating[0],
          justify: recommends[0],
        },
        support: {
          rate: starRating[1],
          justify: recommends[1],
        },
        recommendation: {
          rate: starRating[2],
          justify: recommends[2],
        },
      };

      apiPostUserRateTranspora(params)
        .then((response) => {
          if (response.success) {
            handleNextPage();
          } else {
            setNotification({ error: response.message || 'Fail' });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (currentRating.type === 'carrier') {
      const params: IShipperRateCarrierParams = {
        carrierCompanyId: currentRating.carrierId,
        isSkip: false,
        completenessAndCorrectness: {
          rate: starRating[0],
          justify: recommends[0],
        },
        friendlinessAndCommunication: {
          rate: starRating[1],
          justify: recommends[1],
        },
        recommendation: {
          rate: starRating[2],
          justify: recommends[2],
        },
      };

      apiPostShipperRateCarrier(params)
        .then((response) => {
          if (response.success) {
            handleNextPage();
          } else {
            setNotification({ error: response.message || 'Fail!' });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSkipRating = () => {
    if (currentRating.type === 'transpora') {
      apiPostUserRateTranspora({ isSkip: true })
        .then((response) => {
          if (response.success) {
            handleNextPage();
          } else {
            setNotification({ error: response.message || 'Fail' });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (currentRating.type === 'carrier') {
      apiPostShipperRateCarrier({
        isSkip: true,
        carrierCompanyId: currentRating.carrierId,
      })
        .then((response) => {
          if (response.success) {
            handleNextPage();
          } else {
            setNotification({ error: response.message || 'Fail' });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChangeCurrentRating = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
    setCurrentRating(ratingList[page - 1]);
    setStarRating([0, 0, 0]);
    setRecommends(['', '', '']);
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth={'md'} scroll={'paper'}>
      <DialogTitle>
        <Typography sx={{ fontWeight: '700', fontSize: 24 }}>
          {ratingContent?.title.mainTitle}{' '}
          <Typography
            component="span"
            sx={{
              fontWeight: '700',
              fontSize: 24,
              textTransform: 'capitalize',
            }}
          >
            {currentRating.type === 'carrier' ? currentRating.companyName : ''}
          </Typography>
        </Typography>
        <Typography
          component="span"
          sx={{ display: 'inline-block', marginBottom: 2 }}
        >
          {ratingContent?.title.subtitle}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: '0 24px' }}>
        {ratingContent?.questions.map((question, i) => (
          <RatingQuestion
            key={i}
            number={i}
            topic={question.topic}
            question={question.question}
            starRating={starRating}
            setStarRating={setStarRating}
            recommend={recommends}
            setRecommends={setRecommends}
          />
        ))}
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'end',
          flexWrap: 'wrap',
        }}
      >
        {totalRating > 1 ? (
          <Pagination
            count={totalRating}
            onChange={handleChangeCurrentRating}
          />
        ) : null}
        <ButtonGroup>
          <Button
            sx={{
              backgroundColor: '#F4F4F4',
              color: 'black',
              '&:hover': { backgroundColor: '#CCC' },
            }}
            onClick={handleSkipRating}
          >
            {t('rating.skipButton')}
          </Button>
          <Button
            onClick={handleSubmitRating}
            sx={{ marginLeft: 2 }}
            disabled={starRating.some((star) => star === 0) ? true : false}
          >
            {t('rating.saveButton')}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

const ButtonGroup = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: 12,
});

export default RatingFormWrapper;
