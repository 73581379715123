import Box from '@mui/material/Box';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from 'components/Logo';
import RouteLink from 'components/RouteLink';
import Scrollbar from 'components/Scrollbar';
import TableAvatar from 'components/Table/TableAvatar';
import { logoImages } from 'constants/placeholderImages';
import useAuth from 'hooks/useAuth';
import usePrevious from 'hooks/usePrevious';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

interface Props {
  onCloseSidebar: () => void;
  openSidebar: boolean;
  collapsed: boolean;
}

const DashboardSidebar: FC<Props> = (props) => {
  const { onCloseSidebar, openSidebar, collapsed } = props;
  const { user, company } = useAuth();
  const { pathname } = useLocation();
  const prevPathName = usePrevious(pathname);
  const theme = useTheme();

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    if (prevPathName !== pathname && openSidebar) {
      onCloseSidebar();
    }
  }, [pathname, onCloseSidebar, openSidebar, prevPathName]);

  const userInfo = {
    companyName: company ? company.companyName : 'Disporeon GmbH',
    userName: user ? user.fullName : 'Disporeon',
    image: user ? user.image : '',
  };

  const normal = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Hidden lgUp>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <RouteLink to="/">Disporeon</RouteLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              px: 2,
              py: 1.5,
            }}
          >
            <Logo
              src={logoImages.image}
              style={{ maxWidth: '100%', marginLeft: '-5px', height: '42px' }}
            />
            <RouteLink to="/user/profile">
              <TableAvatar src={userInfo.image} type="user" />
            </RouteLink>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
              <Typography variant="subtitle2">{userInfo.userName}</Typography>
              <Typography color="text.secondary" variant="caption">
                {userInfo.companyName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Sidebar collapsed={false} openSidebar={true} />
      </Box>
    </Scrollbar>
  );

  const collapse = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Hidden lgUp>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <RouteLink to="/">Disporeon</RouteLink>
          </Box>
        </Hidden>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 64,
            ...(!collapsed && {
              justifyContent: 'flex-start',
              px: 3.5,
            }),
          }}
        >
          <Logo
            src={collapsed ? logoImages.text : logoImages.image}
            style={
              collapsed
                ? { width: '100%', height: '64px' }
                : { maxWidth: '100%', marginLeft: '-5px', height: '42px' }
            }
          />
        </Box>
        {!collapsed && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 3.5,
              pt: 1.5,
            }}
          >
            <RouteLink to="/user/profile">
              <TableAvatar src={userInfo.image} type="user" />
            </RouteLink>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
              <Typography variant="subtitle2">{userInfo.userName}</Typography>
              <Typography color="text.secondary" variant="caption">
                {userInfo.companyName}
              </Typography>
            </Box>
          </Box>
        )}
        <Sidebar collapsed={collapsed} openSidebar={openSidebar} />
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <CollapsibleDrawer
        anchor="left"
        collapsed={collapsed}
        variant="permanent"
      >
        {collapse}
      </CollapsibleDrawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={openSidebar}
      onClose={onCloseSidebar}
      PaperProps={{ sx: { width: 280 } }}
      variant="temporary"
    >
      {normal}
    </Drawer>
  );
};

const CollapsibleDrawer = styled(Drawer, {
  shouldForwardProp: (prop: string) => !['collapsed'].includes(prop),
})<{ collapsed: boolean }>(({ collapsed }) => ({
  [`& .${drawerClasses.paper}`]: {
    width: 280,
    ...(collapsed && {
      width: 75,
    }),
  },
}));

export default DashboardSidebar;
