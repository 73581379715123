import Menu from '@mui/material/Menu';
import type { FC } from 'react';

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
}

const SubMenuItem: FC<Props> = (props) => {
  const { open, anchorEl, handleClose, children } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        elevation: 1,
        sx: { ml: 1 },
      }}
    >
      {children}
    </Menu>
  );
};

export default SubMenuItem;
