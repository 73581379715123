import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthLogFiles } from 'services/configurations/security/logFiles';
import type { LogFiles } from 'types/configurations/security';

interface CommonParams {
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  searchText: string;
}

interface GetLogFilesResponse {
  logFiles: LogFiles;
  totalRows: number;
}
const getLogFiles = createAsyncThunk<GetLogFilesResponse, CommonParams>(
  'configurations/security/getLogFiles',
  async (params) => {
    const { data, total } = await getAuthLogFiles(params);
    return { logFiles: data || [], totalRows: total };
  }
);

export { getLogFiles };
