import { COMPANY_ROLES } from 'constants/role';
import usePermissions from 'hooks/usePermissions';
import useUser from 'hooks/useUser';
import type { FC } from 'react';
import { Fragment } from 'react';
import type { CompanyRole, PermissionPath } from 'types/role';
import PermissionDenied from 'views/Errors/PermissionDenied';

interface Props {
  companyRoles: CompanyRole[];
  path: PermissionPath;
}

const PrivateRoleRoute: FC<Props> = (props) => {
  const { children, path, companyRoles } = props;
  const { user, company } = useUser();
  const allPermission = usePermissions();

  const { companyRoleIdList } = company;
  const {
    userRole: { permissions: userPermissions },
  } = user;

  const roles = companyRoleIdList.map((roleId) => COMPANY_ROLES[roleId]);

  const hasPermission =
    path === '*'
      ? true
      : userPermissions.some((permission) => permission.code === path) &&
        allPermission.some((permission) => permission.code === path);

  const companyRestricted = companyRoles.includes('*')
    ? true
    : roles.some((role) => companyRoles.includes(role));

  if (!(hasPermission && companyRestricted)) {
    return <PermissionDenied />;
  }

  return <Fragment>{children}</Fragment>;
};

export default PrivateRoleRoute;
