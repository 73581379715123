import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from 'components/ErrorBoundary';
import GlobalBaseline from 'components/GlobalBaseline';
import SplashScreen from 'components/SplashScreen';
import { AuthConsumer, AuthProvider } from 'contexts/AuthContext';
import { NotificationProvider } from 'contexts/NotificationContext';
import { SettingsProvider } from 'contexts/SettingsContext';
import { SignalrProvider } from 'contexts/SignalrContext';
import locale from 'date-fns/locale/en-GB';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import store from 'store';
import history from 'utils/history';
import './App.css';
import './i18n';
import Routers from './routers';

const App = () => {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <HistoryRouter history={history}>
          <AuthProvider>
            <SettingsProvider>
              <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
                <SignalrProvider>
                  <ErrorBoundary>
                    <NotificationProvider>
                      <CssBaseline />
                      <GlobalBaseline />
                      <AuthConsumer>
                        {(auth) =>
                          !auth || !auth.isInitialized ? (
                            <SplashScreen />
                          ) : (
                            <Routers />
                          )
                        }
                      </AuthConsumer>
                    </NotificationProvider>
                  </ErrorBoundary>
                </SignalrProvider>
              </LocalizationProvider>
            </SettingsProvider>
          </AuthProvider>
        </HistoryRouter>
      </ReduxProvider>
    </HelmetProvider>
  );
};

export default App;
