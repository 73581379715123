import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  company: {
    id: 0,
    companyName: '',
    email: '',
    countryId: 0,
    phoneNumberCountryCode: '',
    phoneNumber: '',
    companyStreetName: '',
    companyStreetNumber: '',
    cityName: '',
    cityCode: '',
    companyStatusId: 0,
    uidNumber: '',
    image: '',
    imageType: '',
    divisionId: 0,
    website: '',
  },
  brandId: 0,
  brandName: '',
  productFamilyId: 0,
  productFamilyName: '',
  companyId: 0,
  vehicleTypeId: 0,
  vehicleTypeName: '',
  exhaustCategoryId: 0,
  freightCategoryId: 0,
  type: '',
  description: '',
  inventoryNo: '',
  serialNo: '',
  plate: '',
  horsePower: 0,
  kilowatt: 0,
  length: 0,
  width: 0,
  height: 0,
  weight: 0,
  totalWeight: 0,
  loadingWeight: 0,
  payload: 0,
  driveType: '',
  mobileNumberCountryCode: '',
  mobileNumber: '',
  imageUrl: [],
  initialRegistration: '',
};

const vehicleTruckSlice = createSlice({
  name: '@VehicleTruckSlice',
  initialState,
  reducers: {
    addTruck(_state, action) {
      return action.payload;
    },
  },
});

export const { addTruck } = vehicleTruckSlice.actions;
export default vehicleTruckSlice.reducer;
