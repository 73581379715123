import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { TabValue } from 'views/CRM/Accounts/AccountDetails/AccountDetailsTab';

type TabAction = 'create' | 'update' | 'details' | 'none';

interface State {
  newRegistration: number;
  accounts: {
    tab: TabValue;
    operation: TabAction;
    employeeId: number | null;
  };
}

const initialState: State = {
  newRegistration: 0,
  accounts: {
    tab: 'account',
    operation: 'none',
    employeeId: null,
  },
};

const crmSlice = createSlice({
  name: 'crm',
  initialState,
  reducers: {
    initNewRegistration(state, action: PayloadAction<number>) {
      state.newRegistration = action.payload;
    },
    verifyRegistration(state) {
      state.newRegistration -= 1;
    },
    setTab(
      state,
      action: PayloadAction<{
        tab: TabValue;
        operation: TabAction;
        employeeId?: number | null;
      }>
    ) {
      const { tab, operation, employeeId } = action.payload;
      state.accounts.tab = tab;
      state.accounts.operation = operation;
      state.accounts.employeeId = employeeId || null;
    },
  },
});

export const { initNewRegistration, verifyRegistration, setTab } =
  crmSlice.actions;
export default crmSlice.reducer;
