import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './locales/de.json';
import en from './locales/en.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import ratingDe from '../views/RatingForm/utils/languages/de.json';
import ratingEn from '../views/RatingForm/utils/languages/en.json';
import ratingFr from '../views/RatingForm/utils/languages/fr.json';
import ratingIt from '../views/RatingForm/utils/languages/it.json';
import bookingCalculationsDe from '../views/Configurations/Markets/Countries/CountryTabs/BookingCalculation/locales/de.json';
import bookingCalculationsEn from '../views/Configurations/Markets/Countries/CountryTabs/BookingCalculation/locales/en.json';
import bookingCalculationsFr from '../views/Configurations/Markets/Countries/CountryTabs/BookingCalculation/locales/fr.json';
import bookingCalculationsIt from '../views/Configurations/Markets/Countries/CountryTabs/BookingCalculation/locales/it.json';
import crossBorderSettingsDe from '../views/Configurations/Markets/Countries/CountryTabs/CrossBorderSettings/locales/de.json';
import crossBorderSettingsEn from '../views/Configurations/Markets/Countries/CountryTabs/CrossBorderSettings/locales/en.json';
import crossBorderSettingsFr from '../views/Configurations/Markets/Countries/CountryTabs/CrossBorderSettings/locales/fr.json';
import crossBorderSettingsIt from '../views/Configurations/Markets/Countries/CountryTabs/CrossBorderSettings/locales/it.json';
import bookingDe from '../views/DispoAssistent/Booking/locales/de.json';
import bookingEn from '../views/DispoAssistent/Booking/locales/en.json';
import bookingFr from '../views/DispoAssistent/Booking/locales/fr.json';
import bookingIt from '../views/DispoAssistent/Booking/locales/it.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        ...en,
        ...ratingEn,
        ...bookingCalculationsEn,
        ...bookingEn,
        ...crossBorderSettingsEn,
      },
    },
    de: {
      translation: {
        ...de,
        ...ratingDe,
        ...bookingCalculationsDe,
        ...bookingDe,
        ...crossBorderSettingsDe,
      },
    },
    fr: {
      translation: {
        ...fr,
        ...ratingFr,
        ...bookingCalculationsFr,
        ...bookingFr,
        ...crossBorderSettingsFr,
      },
    },
    it: {
      translation: {
        ...it,
        ...ratingIt,
        ...bookingCalculationsIt,
        ...bookingIt,
        ...crossBorderSettingsIt,
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
