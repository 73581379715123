import PrivateRoleRoute from 'components/PrivateRoleRoute';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Loadable from './Loadable';

// Registrations
const Registrations = Loadable(lazy(() => import('views/CRM/Registrations')));
const EditRegistration = Loadable(lazy(() => import('views/CRM/Registrations/EditRegistration')));

// Accounts
const Accounts = Loadable(lazy(() => import('views/CRM/Accounts')));
const CreateAccount = Loadable(lazy(() => import('views/CRM/Accounts/CreateAccount')));
const AccountDetails = Loadable(lazy(() => import('views/CRM/Accounts/AccountDetails')));

// Contact
const AllUserAccounts = Loadable(lazy(() => import('views/CRM/AllUserAccounts')));
const CreateUserAccount = Loadable(lazy(() => import('views/CRM/AllUserAccounts/CreateUserAccount')));
const UserAccountDetails = Loadable(lazy(() => import('views/CRM/AllUserAccounts/UserAccountDetails')));
const EditUserAccount = Loadable(lazy(() => import('views/CRM/AllUserAccounts/EditUserAccount')));

// Segment
const Segments = Loadable(lazy(() => import('views/CRM/Segments')));
const CreateSegment = Loadable(lazy(() => import('views/CRM/Segments/CreateSegment')));
const SegmentDetails = Loadable(lazy(() => import('views/CRM/Segments/SegmentDetails')));
const EditSegment = Loadable(lazy(() => import('views/CRM/Segments/EditSegment')));

// Terms conditions
const TermsConditions = Loadable(lazy(() => import('views/CRM/TermsConditions')));
const CreateTermsConditions = Loadable(lazy(() => import('views/CRM/TermsConditions/CreateTermsConditions')));
const EditTermsConditions = Loadable(lazy(() => import('views/CRM/TermsConditions/EditTermsConditions')));
const BlackList = Loadable(lazy(() => import('views/CRM/DataProtection/BlackList')));

const CRMRoutes: RouteObject = {
  path: 'crm',
  element: <Outlet />,
  children: [
    {
      path: 'registrations',
      element: (
        <PrivateRoleRoute companyRoles={['internal']} path="newRegistration">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <Registrations /> },
        { path: ':registrationId/edit', element: <EditRegistration /> },
      ],
    },
    {
      path: 'accounts',
      element: (
        <PrivateRoleRoute companyRoles={['internal']} path="account">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <Accounts /> },
        { path: 'create', element: <CreateAccount /> },
        { path: ':companyId', element: <AccountDetails /> },
      ],
    },
    {
      path: 'users',
      element: (
        <PrivateRoleRoute companyRoles={['internal']} path="contact">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <AllUserAccounts /> },
        { path: 'create', element: <CreateUserAccount /> },
        { path: ':userId', element: <UserAccountDetails /> },
        { path: ':userId/edit', element: <EditUserAccount /> },
      ],
    },
    {
      path: 'segments',
      element: (
        <PrivateRoleRoute companyRoles={['internal']} path="segment">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <Segments /> },
        { path: 'create', element: <CreateSegment /> },
        { path: ':segmentId', element: <SegmentDetails /> },
        { path: ':segmentId/edit', element: <EditSegment /> },
      ],
    },
    {
      path: 'terms-conditions',
      element: (
        <PrivateRoleRoute companyRoles={['internal']} path="term">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <TermsConditions /> },
        { path: 'create', element: <CreateTermsConditions /> },
        { path: ':termId/edit', element: <EditTermsConditions /> },
      ],
    },
    {
      path: 'data-protection',
      element: (
        <PrivateRoleRoute companyRoles={['internal']} path="*">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: < BlackList/> },
        { path: 'black-list', element: <BlackList /> },
      ],
    },
  ],
};

export default CRMRoutes;
