import type { RouteObject } from 'react-router-dom';
import Loadable from './Loadable';
import { lazy } from 'react';

// Dashboard
const Dashboard = Loadable(lazy(() => import('views/Dashboard')));

const DashboardRoutes: RouteObject = {
  path: 'dashboard',
  element: <Dashboard />,
};

export default DashboardRoutes;
