import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { startOfMonth } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import DateFns from 'utils/DateFns';
import sample from 'utils/sample';

const colours = [
  'primary.main',
  'secondary.main',
  'info.main',
  'warning.main',
  'success.main',
  'error.main',
];

interface TimeLine {
  from: Date;
  to: Date;
}

interface Props {
  timeline: TimeLine;
  name: string;
}

const Cluster = (props: Props) => {
  const { timeline, name } = props;

  const { from, to } = timeline;

  const start = startOfMonth(new Date('2020-1-1'));
  const left = differenceInDays(from, start) * 2;
  const width = differenceInDays(to, from) * 2;
  const bgcolor = sample(colours);

  return (
    <Tooltip
      arrow
      componentsProps={{ tooltip: { sx: { borderRadius: 0 } } }}
      title={
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle2">{name}</Typography>
          <Typography variant="caption">
            From:{' '}
            <Typography
              variant="caption"
              component="span"
              sx={{ fontWeight: 'bold' }}
            >
              {DateFns.format(from, 'dd.MM.yyyy')}
            </Typography>
          </Typography>
          <Typography variant="caption">
            To:{' '}
            <Typography
              variant="caption"
              component="span"
              sx={{ fontWeight: 'bold' }}
            >
              {DateFns.format(to, 'dd.MM.yyyy')}
            </Typography>
          </Typography>
        </Box>
      }
    >
      <Box
        sx={{
          bgcolor,
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left,
          width,
          height: 20,
          cursor: 'pointer',
        }}
      />
    </Tooltip>
  );
};

export default Cluster;
