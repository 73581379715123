import PrivateRoleRoute from 'components/PrivateRoleRoute';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Loadable from './Loadable';

const TransportConfirmations = Loadable(lazy(() => import('views/Documents/TransportConfirmations')));
const DeliveryNotes = Loadable(lazy(() => import('views/Documents/DeliveryNotes')));
const SuccessCommissions = Loadable(lazy(() => import('views/Documents/SuccessCommissions')));
const TermsConditions = Loadable(lazy(() => import('views/Documents/TermsConditions')));
const RentalContracts = Loadable(lazy(() => import('views/Documents/RentalContracts')));

// Document details
const TransportConfirmationDetails = Loadable(
  lazy(() => import('views/Documents/TransportConfirmations/TransportConfirmationDetails'))
);
const DeliveryNotesDetails = Loadable(lazy(() => import('views/Documents/DeliveryNotes/DeliveryNotesDetails')));
const RentalContractDetails = Loadable(lazy(() => import('views/Documents/RentalContracts/RentalContractsTable/RentalContractDetails')));

const DocumentRoutes: RouteObject = {
  path: 'documents',
  element: (
    <PrivateRoleRoute companyRoles={['*']} path="document">
      <Outlet />
    </PrivateRoleRoute>
  ),
  children: [
    { index: true, element: <TransportConfirmations /> },
    {
      path: 'transport-confirmations',
      children: [
        { index: true, element: <TransportConfirmations /> },
        { path: ':tenderId', element: <TransportConfirmationDetails /> },
      ],
    },
    {
      path: 'delivery-notes',
      children: [
        { index: true, element: <DeliveryNotes /> },
        { path: ':tenderId', element: <DeliveryNotesDetails /> },
      ],
    },
    {
      path: 'success-commissions',
      children: [{ index: true, element: <SuccessCommissions /> }],
    },
    {
      path: 'rental-contracts',
      element: (
        <PrivateRoleRoute companyRoles={['rental','renter']} path="document">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <RentalContracts /> },
        {path: ':tenderId', element: <RentalContractDetails/>}
    ],

    },
    {
      path: 'terms-conditions',
      element: (
        <PrivateRoleRoute companyRoles={['shipper', 'carrier']} path="document">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [{ index: true, element: <TermsConditions /> }],
    },
  ],
};

export default DocumentRoutes;
