import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';
import SplashRoute from 'components/SplashRoute';
import DashboardLayout from 'layouts/Dashboard';
import MainLayout from 'layouts/Main';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import ForgotPassword from 'views/ForgotPassword';
import ResetPassword from 'views/ResetPassword';
import CompanySettingsRoutes from './CompanySettings';
import ConfigurationADMsRoutes from './ConfigurationADMs';
import CRMRoutes from './CRM';
import DashboardRoutes from './Dashboard';
import DocumentRoutes from './Documents';
import Loadable from './Loadable';
import TendersRoutes from './Tenders';
import TenderWizard from './TenderWizard';
import TransportsRoutes from './Transports';
import UserRoutes from './User';
import Experiment from 'views/Experiment';
import RentingRoutes from './Renting';
import DispoAssistentRoutes from './DispoAssistent';

// Authentication
const Login = Loadable(lazy(() => import('views/Login')));
const Register = Loadable(lazy(() => import('views/Register')));
const VerifyRegistration = Loadable(lazy(() => import('views/Register/VerifyRegistration')));
const UnsubscribeEmail = Loadable(lazy(() => import('views/Unsubscribe')));

// Notfound
const NotFound = Loadable(lazy(() => import('views/Errors/NotFound')));

// Test
const Test = Loadable(lazy(() => import('views/Test')));

const routes: RouteObject[] = [
  {
    path: '/login',
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: '/register',
    element: (
      <PublicRoute>
        <Register />
      </PublicRoute>
    ),
  },
  {
    path: '/verify-registration',
    element: (
      <VerifyRegistration />
    ),
  },
  {
    path: '/unsubscribe-email',
    element: (
      <UnsubscribeEmail/>
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <PublicRoute>
        <ForgotPassword />
      </PublicRoute>
    ),
  },
  {
    path: '/reset-password',
    element: (
      <PublicRoute>
        <ResetPassword />
      </PublicRoute>
    ),
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      { index: true, element: <SplashRoute /> },
      { path: '/experiment', element: <Experiment /> },
      { path: '/test', element: <Test /> },
      DashboardRoutes,
      TenderWizard,
      CompanySettingsRoutes,
      CRMRoutes,
      ConfigurationADMsRoutes,
      UserRoutes,
      TransportsRoutes,
      TendersRoutes,
      DocumentRoutes,
      RentingRoutes,
      DispoAssistentRoutes,
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      { index: true, element: <NotFound /> },
      { path: '*', element: <NotFound /> },
    ],
  },
];

const Router = () => {
  const element = useRoutes(routes);
  return element;
};

export default Router;
