import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface State {
  htmlString: string;
}

const initialState: State = {
  htmlString: '',
};

const emailEditorSlice = createSlice({
  name: 'emailEditor',
  initialState,
  reducers: {
    setHtmlString(state, action: PayloadAction<State['htmlString']>) {
      state.htmlString = action.payload;
    },
  },
});

export const { setHtmlString } = emailEditorSlice.actions;
export default emailEditorSlice.reducer;
