import PrivateRoleRoute from 'components/PrivateRoleRoute';
import Routing from 'constants/routing';
import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import Loadable from './Loadable';

// Tender wizard
const DispoAssistent = Loadable(lazy(() => import('views/DispoAssistent')));
const Booking = Loadable(lazy(() => import('views/DispoAssistent/Booking')));

const DispoAssistentRoutes: RouteObject = {
  path: Routing.dispoAssistent,
  element: (
    <PrivateRoleRoute companyRoles={['internal', 'shipper']} path="tender">
      <Outlet />
    </PrivateRoleRoute>
  ),
  children:[
    {index:true,element:<DispoAssistent/>},
    {path:'booking',element:<Booking/>}

  ]
};

export default DispoAssistentRoutes;
