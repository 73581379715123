import PrivateRoleRoute from 'components/PrivateRoleRoute';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Loadable from './Loadable';

// Employees
const Employees = Loadable(lazy(() => import('views/CompanySettings/Organisation/Employees')));
const CreateEmployee = Loadable(lazy(() => import('views/CompanySettings/Organisation/Employees/CreateEmployee')));
const EditEmployee = Loadable(lazy(() => import('views/CompanySettings/Organisation/Employees/EditEmployee')));
const EmployeeDetails = Loadable(lazy(() => import('views/CompanySettings/Organisation/Employees/EmployeeDetails')));

// Locations
const Locations = Loadable(lazy(() => import('views/CompanySettings/Organisation/Locations')));
const CreateLocation = Loadable(lazy(() => import('views/CompanySettings/Organisation/Locations/CreateLocation')));
const EditLocation = Loadable(lazy(() => import('views/CompanySettings/Organisation/Locations/EditLocation')));
const LocationDetails = Loadable(lazy(() => import('views/CompanySettings/Organisation/Locations/LocationDetails')));

// Addresses
const Addresses = Loadable(lazy(() => import('views/CompanySettings/Organisation/Addresses')));
const CreateAddress = Loadable(lazy(() => import('views/CompanySettings/Organisation/Addresses/CreateAddress')));
const AddressDetails = Loadable(lazy(() => import('views/CompanySettings/Organisation/Addresses/AddressDetails')));
const EditAddress = Loadable(lazy(() => import('views/CompanySettings/Organisation/Addresses/EditAddress')));

// Contacts
const Contacts = Loadable(lazy(() => import('views/CompanySettings/Organisation/Contacts')));
const CreateContact = Loadable(lazy(() => import('views/CompanySettings/Organisation/Contacts/CreateContact')));
const EditContact = Loadable(lazy(() => import('views/CompanySettings/Organisation/Contacts/EditContact')));
const ContactDetails = Loadable(lazy(() => import('views/CompanySettings/Organisation/Contacts/ContactDetails')));

// Company
const Company = Loadable(lazy(() => import('views/CompanySettings/Organisation/Company')));

// Vehicles ConstructionMachineryEquipment
const Loads = Loadable(lazy(() => import('views/CompanySettings/Vehicles/ConstructionMachineryEquipment')));
const ConstructionMachineryEquipmentDetails = Loadable(lazy(() => import('views/CompanySettings/Vehicles/ConstructionMachineryEquipment/ConstructionMachineryEquipmentDetails')));
const CreateConstructionMachineryEquipment = Loadable(lazy(() => import('views/CompanySettings/Vehicles/ConstructionMachineryEquipment/CreateConstructionMachineryEquipment')));
const EditConstructionMachineryEquipment = Loadable(lazy(() => import('views/CompanySettings/Vehicles/ConstructionMachineryEquipment/EditConstructionMachineryEquipment')));

// Bidder
const BidderLists = Loadable(lazy(() => import('views/CompanySettings/BidderLists')));
const CreateBidderList = Loadable(lazy(() => import('views/CompanySettings/BidderLists/CreateBidderLists')));
const BidderListDetails = Loadable(lazy(() => import('views/CompanySettings/BidderLists/BidderListDetails')));
const EditBidderList = Loadable(lazy(() => import('views/CompanySettings/BidderLists/EditBidderList')));

const CompanySettingsRoutes: RouteObject = {
  path: 'company-settings',
  element: <Outlet />,
  children: [
    {
      path: 'construction-machinery-equipment',
      element: (
        <PrivateRoleRoute companyRoles={['shipper','rental']} path="load">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <Loads /> },
        { path: ':loadId', element: <ConstructionMachineryEquipmentDetails /> },
        { path: 'create', element: <CreateConstructionMachineryEquipment /> },
        { path: ':loadId/edit', element: <EditConstructionMachineryEquipment /> },
      ],
    },
    {
      path: 'bidder-lists',
      element: (
        <PrivateRoleRoute companyRoles={['shipper','rental']} path="*">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <BidderLists /> },
        { path: ':id', element: <BidderListDetails /> },
        { path: 'create', element: <CreateBidderList /> },
        { path: ':id/edit', element: <EditBidderList /> },
      ],
    },
    {
      path: 'organisation',
      element: <Outlet />,
      children: [
        {
          path: 'employees',
          element: (
            <PrivateRoleRoute companyRoles={['shipper', 'carrier','rental']} path="employee">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <Employees /> },
            { path: 'create', element: <CreateEmployee /> },
            { path: ':employeeId', element: <EmployeeDetails /> },
            { path: ':employeeId/edit', element: <EditEmployee /> },
          ],
        },
        {
          path: 'locations',
          element: (
            <PrivateRoleRoute companyRoles={['shipper', 'carrier','rental']} path="location">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <Locations /> },
            { path: 'create', element: <CreateLocation /> },
            { path: ':locationId', element: <LocationDetails /> },
            { path: ':locationId/edit', element: <EditLocation /> },
          ],
        },
        {
          path: 'addresses',
          element: (
            <PrivateRoleRoute companyRoles={['shipper','rental']} path="address">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <Addresses /> },
            { path: 'create', element: <CreateAddress /> },
            { path: ':addressId', element: <AddressDetails /> },
            { path: ':addressId/edit', element: <EditAddress /> },
          ],
        },
        {
          path: 'contacts',
          element: (
            <PrivateRoleRoute companyRoles={['shipper','rental']} path="contact">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <Contacts /> },
            { path: 'create', element: <CreateContact /> },
            { path: ':contactId', element: <ContactDetails /> },
            { path: ':contactId/edit', element: <EditContact /> },
          ],
        },
        {
          path: 'company',
          element: (
            <PrivateRoleRoute companyRoles={['shipper', 'carrier','rental']} path="company">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [{ index: true, element: <Company /> }],
        },
      ],
    },
  ],
};

export default CompanySettingsRoutes;
