import { ThemeProvider } from '@mui/material/styles';
import type { FC } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createTheme } from 'theme';
import type { LanguageCode, Order } from 'types/common';
import LocalStorage from 'utils/LocalStorage';
import { DASHBOARD_LANGUAGE } from 'constants/languageCode';
import useAuth from 'hooks/useAuth';

export interface ThemeSettings {
  language: LanguageCode | null;
  rowsPerPage: number;
  filters: { sortBy: string; sortDirection: Order };
}

export interface SettingsContextValue {
  settings: ThemeSettings;
  setThemeSettings: (settings: Partial<ThemeSettings>) => void;
}

const initialSettings: ThemeSettings = {
  language: null,
  rowsPerPage: 10,
  filters: { sortBy: '', sortDirection: '' },
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  setThemeSettings: () => {},
});

if (process.env.NODE_ENV === 'development') {
  SettingsContext.displayName = 'SettingsContext';
}

const SettingsProvider: FC = ({ children }) => {
  const [settings, setSettings] = useState<ThemeSettings>(initialSettings);
  const { i18n } = useTranslation();
  const { user } = useAuth();

  useEffect(() => {
    const storedSettings = LocalStorage.get('settings', initialSettings);
    if (
      typeof storedSettings === 'object' &&
      Object.keys(initialSettings).every((setting) => setting in storedSettings)
    ) {
      const { language, rowsPerPage } = storedSettings as ThemeSettings;
      const browserLanguage = window.navigator.language.split('-')[0] || 'en';

      //language in settings or browser
      const currentLanguage = language || browserLanguage;

      // default is en if user language does not exist in dashboard
      const displayLanguage = DASHBOARD_LANGUAGE.includes(currentLanguage)
        ? currentLanguage
        : 'en';

      const displayRowsPerPage = [10, 25, 50, 100].includes(rowsPerPage)
        ? rowsPerPage
        : 10;

      setSettings({ ...storedSettings, rowsPerPage: displayRowsPerPage });
      i18n.changeLanguage(displayLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    if (user) {
      const { languageCode } = user;
      // default is en if user language does not exist in dashboard
      const displayLanguage = DASHBOARD_LANGUAGE.includes(languageCode)
        ? languageCode
        : 'en';

      setSettings((state) => {
        LocalStorage.set('settings', {
          ...state,
          language: displayLanguage,
        });
        return {
          ...state,
          language: displayLanguage,
        };
      });

      i18n.changeLanguage(displayLanguage);
    }
  }, [user, i18n]);

  const theme = useMemo(() => createTheme(), []);

  const setThemeSettings = useCallback(
    (updatedSettings: Partial<ThemeSettings> = initialSettings) => {
      setSettings((state) => {
        LocalStorage.set('settings', {
          ...state,
          ...updatedSettings,
        });
        return {
          ...state,
          ...updatedSettings,
        };
      });
    },
    []
  );

  return (
    <SettingsContext.Provider value={{ settings, setThemeSettings }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </SettingsContext.Provider>
  );
};

export { SettingsContext as default, SettingsProvider };
