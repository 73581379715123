import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogFooter from 'components/Dialog/DialogFooter';
import DialogForm from 'components/Dialog/DialogForm';
import DialogHeader from 'components/Dialog/DialogHeader';
import ControllerTextField from 'components/Form/ControllerTextField';
import FormGroup from 'components/Form/FormGroup';
import FormLabel from 'components/Form/FormLabel';
import useAuth from 'hooks/useAuth';
import useMounted from 'hooks/useMounted';
import useNotification from 'hooks/useNotification';
import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { changePasswordDefault } from 'services/shared/auth';
import LocalStorage from 'utils/LocalStorage';
import TypedObject from 'utils/TypedObject';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('schema.required')
    .trim('schema.trim')
    .strict(true)
    .default(''),
  retypeNewPassword: yup
    .string()
    .required('schema.required')
    .trim('schema.trim')
    .default('')
    .oneOf([yup.ref('newPassword')], 'schema.passwordDoesNotMatch'),
});

interface FormData {
  newPassword: string;
  retypeNewPassword: string;
}

export interface LoginInfo {
  isChangePasswordDefault: boolean;
}

const ChangePasswordContext: FC = ({ children }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const setNotification = useNotification();
  const { user, logout } = useAuth();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const mounted = useMounted();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: validationSchema.getDefault(),
  });

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    if (mounted.current) {
      setOpenDialog(false);
    }
  };

  const onSubmit = async (data: FormData) => {
    try {
      if (!user) return;

      setLoading(true);

      const response = await changePasswordDefault({
        email: user.email,
        newPassword: data.newPassword,
      });

      if (response.success) {
        setNotification({
          message: t('user.changePassword.response.update.success'),
          severity: 'success',
        });

        reset(validationSchema.getDefault());

        handleCloseDialog();

        const storedLoginInfo = LocalStorage.get('storedLoginInfo');
        LocalStorage.set('storedLoginInfo', {
          ...storedLoginInfo,
          isChangePasswordDefault: true,
        });
      } else {
        if (response.messageCode === 'MSG0103') {
          setNotification({
            error: t(`MSG.${response.messageCode}`),
          });
        } else {
          setNotification({
            error: t('user.changePassword.response.update.error'),
          });
        }
      }
    } catch (error) {
      setNotification({
        error: t('user.changePassword.response.update.error'),
      });
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  };

  const [newPassword, retypeNewPassword] = watch([
    'newPassword',
    'retypeNewPassword',
  ]);

  useEffect(() => {
    if (newPassword && retypeNewPassword) {
      trigger('retypeNewPassword');
    }
  }, [trigger, newPassword, retypeNewPassword]);

  useEffect(() => {
    if (!user) return;

    const storedLoginInfo = LocalStorage.get('storedLoginInfo');

    if (typeof storedLoginInfo === 'object' && storedLoginInfo) {
      const { isChangePasswordDefault } = storedLoginInfo as LoginInfo;
      if (!isChangePasswordDefault) {
        handleOpenDialog();
      }
    }
  }, [user]);

  const handleCancel = async () => {
    handleCloseDialog();
    logout();
  };

  return (
    <Fragment>
      {children}
      <Dialog
        open={openDialog}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
      >
        <DialogHeader title={t('user.changePassword.title.main')} />
        <DialogForm noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <FormGroup>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormLabel
                    required
                    title={t('form.newPassword')}
                    name="newPassword"
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <ControllerTextField
                    name="newPassword"
                    control={control}
                    type="password"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormGroup>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormLabel
                    required
                    title={t('form.retypeNewPassword')}
                    name="retypeNewPassword"
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <ControllerTextField
                    name="retypeNewPassword"
                    control={control}
                    type="password"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </DialogContent>
          <DialogFooter>
            <Button
              startIcon={<CloseIcon />}
              variant="outlined"
              onClick={handleCancel}
            >
              {t('common.action.cancel')}
            </Button>
            <LoadingButton
              loading={loading}
              startIcon={<SaveIcon />}
              loadingPosition="start"
              type="submit"
              disabled={!TypedObject.isEmpty(errors)}
            >
              {t('user.changePassword.action.save')}
            </LoadingButton>
          </DialogFooter>
        </DialogForm>
      </Dialog>
    </Fragment>
  );
};

export default ChangePasswordContext;
