import PrivateRoleRoute from 'components/PrivateRoleRoute';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Loadable from './Loadable';

// Transports In execution
const InExecution = Loadable(lazy(() => import('views/Transports/InExecution')));

// Transports Completed
const Completed = Loadable(lazy(() => import('views/Transports/Completed')));

// In Excution Details
const InExecutionDetails = Loadable(lazy(() => import('views/Transports/InExecution/InExecutionDetails')));

// Completed Details
const CompletedDetails = Loadable(lazy(() => import('views/Transports/Completed/CompletedDetails')));

const TransportsRoutes: RouteObject = {
  path: 'transports',
  element: <PrivateRoleRoute companyRoles={['shipper', 'carrier','internal']} path="tender"><Outlet /></PrivateRoleRoute>,
  children: [
    {
      path: 'in-execution',
      element: (
        <PrivateRoleRoute companyRoles={['*']} path="tender">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <InExecution /> },
        { path: ':transportId', element: <InExecutionDetails /> },
      ],
    },
    {
      path: 'completed',
      element: (
        <PrivateRoleRoute companyRoles={['*']} path="tender">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <Completed /> },
        { path: ':transportId', element: <CompletedDetails /> },
      ],
    },
  ],
};

export default TransportsRoutes;
