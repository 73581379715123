import _addDays from 'date-fns/addDays';
import _addHours from 'date-fns/addHours';
import _addMinutes from 'date-fns/addMinutes';
import _compareAsc from 'date-fns/compareAsc';
import _compareDesc from 'date-fns/compareDesc';
import _endOfDay from 'date-fns/endOfDay';
import _format from 'date-fns/format';
import _getDate from 'date-fns/getDate';
import _getHours from 'date-fns/getHours';
import _getMinutes from 'date-fns/getMinutes';
import _getMonth from 'date-fns/getMonth';
import _getYear from 'date-fns/getYear';
import _isAfter from 'date-fns/isAfter';
import _isFuture from 'date-fns/isFuture';
import _isValid from 'date-fns/isValid';
import _isWeekend from 'date-fns/isWeekend';
import _set from 'date-fns/set';
import _setHours from 'date-fns/setHours';
import _setMinutes from 'date-fns/setMinutes';
import _startOfYear from 'date-fns/startOfYear';
import _endOfYear from 'date-fns/endOfYear';
import _subMinutes from 'date-fns/subMinutes';

class DateFns {
  public formatDateTime(date: Date, pattern: string) {
    return _format(date, pattern);
  }

  public format(
    date: Date | number,
    pattern: string,
    options?: Parameters<typeof _format>[2]
  ) {
    return _format(date, pattern, options);
  }

  public combineDateTime(date: Date, time: Date): Date {
    const hours = _getHours(time);
    const minutes = _getMinutes(time);
    return _setMinutes(_setHours(date, hours), minutes);
  }

  public combine(date: Date, time: Date): Date {
    const hours = _getHours(time);
    const minutes = _getMinutes(time);
    return _set(date, { hours, minutes, seconds: 0, milliseconds: 0 });
  }

  /**
   * Compare the two dates and return -1 if the first date is after the second, 1 if the first date is before the second or 0 if dates are equal.
   */
  public compare(from: Date, to: Date): number {
    return _compareDesc(from, to);
  }

  /**
   * Compare the two dates and return -1 if the first date is after the second, 1 if the first date is before the second or 0 if dates are equal.
   */
  public compareAsc(from: Date, to: Date): number {
    return _compareAsc(from, to);
  }

  public utcToLocalDate(utc: string): Date {
    const date = new Date(utc);
    const localTime = date.getTime() - date.getTimezoneOffset() * 60 * 1000;
    return new Date(localTime);
  }

  public isFuture(date: string | null) {
    return _isFuture(new Date(date || ''));
  }

  public isWeekend(date: Date | null) {
    if (!date) return false;
    return _isWeekend(date);
  }

  public isSame(dateLeft: Date, dateRight: Date) {
    const isSameDate = _getDate(dateLeft) === _getDate(dateRight);
    const isSameMonth = _getMonth(dateLeft) === _getMonth(dateRight);
    const isSameYear = _getYear(dateLeft) === _getYear(dateRight);

    return isSameDate && isSameMonth && isSameYear;
  }

  public isToday(date: Date) {
    const today = new Date();
    const isSameDate = _getDate(date) === _getDate(today);
    const isSameMonth = _getMonth(date) === _getMonth(today);
    const isSameYear = _getYear(date) === _getYear(today);

    return isSameDate && isSameMonth && isSameYear;
  }

  public addMinutes(date: Date, amount: number) {
    return _addMinutes(date, amount);
  }

  public subMinutes(date: Date, amount: number) {
    return _subMinutes(date, amount);
  }

  public addDays(date: Date, amount: number) {
    return _addDays(date, amount);
  }

  public addHours(date: Date, amount: number) {
    return _addHours(date, amount);
  }

  public isValid(date: any): date is Date {
    return _isValid(date);
  }

  public isAfter(date: Date, dateToCompare: Date): date is Date {
    return _isAfter(date, dateToCompare);
  }

  public areValid(...dates: (Date | null)[]) {
    return dates.every((date) => date instanceof Date && isValid(date));
  }
  public getFirstDayOfMonth() {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  public getP(): Date {
    const date = new Date();
    return _set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  }

  /**
   * Return the formatted date with "P" pattern
   */
  public setP(date: Date): Date {
    return _set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  }

  public getPp(): Date {
    const date = new Date();
    return _set(date, { seconds: 0, milliseconds: 0 });
  }

  public setPp(date: Date): Date {
    return _set(date, { seconds: 0, milliseconds: 0 });
  }

  public get30DayPriorCurrent() {
    const today = new Date();
    return new Date(new Date().setDate(today.getDate() - 30));
  }

  public getEndOfDay() {
    return _endOfDay(new Date());
  }

  public getFirstDayOfYear() {
    return new Date(new Date().getFullYear(), 0, 1);
  }

  /**
   * Return the start of a year for the given date. The result will be in the local timezone.
   */
  public startOfYear() {
    const today = new Date();
    return _startOfYear(today);
  }

  /**
   * Return the end of a year for the given date. The result will be in the local timezone.
   */
  public endOfYear() {
    const today = new Date();
    return _endOfYear(today);
  }

  /**
   * Return the end of a day for the given date. The result will be in the local timezone.
   */
  public endOfDay(day?: Date | null) {
    const today = new Date();
    return _endOfDay(day || today);
  }
}

const instance = new DateFns();

// Implicit binding 'this' here
export const {
  isValid,
  getP,
  getPp,
  setPp,
  combine,
  format,
  addMinutes,
  subMinutes,
  isSame,
  isWeekend,
  isAfter,
  compare,
  getFirstDayOfYear,
  addDays,
  isToday,
  addHours,
  setP,
  compareAsc,
  startOfYear,
  endOfDay,
  endOfYear,
} = instance;
export default instance;
