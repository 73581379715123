import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Box from '@mui/material/Box';
import type { FC, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import RouteLink from 'components/RouteLink';
import Divider from '@mui/material/Divider';
import { setTabAllNotifications } from 'slices/notifications';
import { useTypedDispatch } from 'store';
import LoadingScreen from 'components/LoadingScreen';

interface Props {
  total: number;
  loading: boolean;
  children: ReactNode;
  onClose: () => void;
}

const NotificationContent: FC<Props> = (props) => {
  const { children, total, onClose, loading } = props;
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const handleClose = () => {
    onClose();
    dispatch(setTabAllNotifications(true));
  };

  if (loading) {
    return (
      <Box
        sx={{
          minHeight: 250,
          overflow: 'hidden',
          display: 'grid',
          placeContent: 'center',
        }}
      >
        <LoadingScreen />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: 250,
        mx: 1,
        display: 'grid',
        gridTemplateRows: '1fr auto',
      }}
    >
      {total === 0 ? (
        <Box
          sx={{
            minHeight: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <NotificationsNoneIcon sx={{ fontSize: 80 }} color="disabled" />
          <Typography color="text.secondary">
            {t('placeholder.noNotifications')}
          </Typography>
        </Box>
      ) : (
        <Box>{children}</Box>
      )}

      {total > 5 && (
        <>
          <Divider />
          <Typography align="center" variant="body2" pt={1}>
            <RouteLink
              to="/user/profile"
              onClick={handleClose}
              underline="hover"
              sx={{
                color: 'primary.main',
              }}
            >
              Show all
            </RouteLink>
          </Typography>
        </>
      )}
    </Box>
  );
};

export default NotificationContent;
