import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
interface TermContents {
  languageId: number | null;
  content: string;
  tab: string;
  documentName: string;
  documentVersion: string;
}

export interface TermsConditionsState {
  title: string;
  version: string;
  countryId: number | null;
  currentTab: string;
  termContents: TermContents[];
}

interface TranslationState {
  currentTab: string;
  termContents: TermContents[];
}

const firstTab = nanoid();

const initialState: TermsConditionsState = {
  title: '',
  version: '',
  countryId: null,
  currentTab: firstTab,
  termContents: [
    {
      languageId: null,
      content: '',
      tab: firstTab,
      documentName: '',
      documentVersion: '',
    },
  ],
};

const vehicleLoadSlice = createSlice({
  name: '@TermsConditions',
  initialState,
  reducers: {
    resetTermsConditions: () => initialState,
    saveDraftTerms(
      state,
      action: PayloadAction<
        Omit<TranslationState, 'termContents'> & {
          termContents: TermContents;
        }
      >
    ) {
      const index = state.termContents.findIndex(
        (termTranslate) => termTranslate.tab === action.payload.termContents.tab
      );

      state.termContents[index] = action.payload.termContents;
    },
    initTermsEdit(state, action: PayloadAction<TermsConditionsState>) {
      const { currentTab, countryId, title, version, termContents } =
        action.payload;

      state.termContents = termContents;
      state.currentTab = currentTab;
      state.title = title;
      state.countryId = countryId;
      state.version = version;
    },
    addTab(state, action: PayloadAction<string>) {
      state.termContents[state.termContents.length] = {
        languageId: null,
        content: '',
        documentName: '',
        documentVersion: '',
        tab: action.payload,
      };
    },
    setCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload;
    },

    closeDraftTerms(state, action: PayloadAction<string>) {
      const index = state.termContents.findIndex(
        (termTranslate) => termTranslate.tab === action.payload
      );

      state.termContents.splice(index, 1);
    },
  },
});

export const {
  saveDraftTerms,
  closeDraftTerms,
  addTab,
  resetTermsConditions,
  initTermsEdit,
  setCurrentTab,
} = vehicleLoadSlice.actions;
export default vehicleLoadSlice.reducer;
