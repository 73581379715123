export type PlaceHolder = 'building' | 'machine' | 'user';
export type Logo = 'image' | 'text';

const placeHolderSmallImages: Record<PlaceHolder, string> = {
  building: '/static/placeholder/Building_image_small.png',
  machine: '/static/placeholder/Machine_image_small.png',
  user: '/static/placeholder/User_image_small.png',
};

const placeHolderWideImages: Record<PlaceHolder, string> = {
  building: '/static/placeholder/Building_image_wide_square.png',
  machine: '/static/placeholder/Machine_image_wide.png',
  user: '/static/placeholder/User_image_wide.png',
};

const logoImages: Record<Logo, string> = {
  image: '/static/logo/logo_image.png',
  text: '/static/logo/logo_text.png',
};

export { placeHolderSmallImages, placeHolderWideImages, logoImages };
