import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import type { AppBarProps } from '@mui/material/AppBar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import LinkIconButton from 'components/LinkIconButton';
import LogoutConfirmDialog from 'components/LogoutConfirmDialog';
import useAuth from 'hooks/useAuth';
import useMounted from 'hooks/useMounted';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import sleep from 'utils/sleep';
import LanguageSwitcher from './LanguageSwitcher';
import Notifications from './Notifications';

interface Props extends AppBarProps {
  onToggleSidebar: () => void;
  onToggleCollapsed: () => void;
  collapsed: boolean;
}

const Header: FC<Props> = (props) => {
  const { collapsed, onToggleSidebar, onToggleCollapsed } = props;
  const { logout } = useAuth();
  const mounted = useMounted();
  const { t } = useTranslation();
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);

  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    if (mounted.current) {
      setOpenLogoutDialog(false);
    }
  };

  const handleLogout = async () => {
    await sleep(350);
    logout();
  };

  return (
    <StyledAppBar collapsed={collapsed} elevation={0}>
      <Toolbar>
        <Hidden lgDown>
          <IconButton
            edge="start"
            onClick={onToggleCollapsed}
            disableRipple
            size="small"
            sx={{
              bgcolor: 'primary.main',
              color: 'common.white',
              borderRadius: 'revert',
              '&:hover': {
                bgcolor: 'primary.main',
              },
              px: 1,
              py: 0.5,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            onClick={onToggleSidebar}
            disableRipple
            size="small"
            sx={{
              bgcolor: 'primary.main',
              color: 'common.white',
              borderRadius: 'revert',
              '&:hover': {
                bgcolor: 'primary.main',
              },
              px: 1,
              py: 0.5,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={1}>
          <LanguageSwitcher />
          <Notifications />
          <LinkIconButton to="/user/profile">
            <IconButton>
              <PersonIcon />
            </IconButton>
          </LinkIconButton>
          <IconButton onClick={handleOpenLogoutDialog}>
            <LogoutIcon />
          </IconButton>
        </Stack>
        <LogoutConfirmDialog
          open={openLogoutDialog}
          onClose={handleCloseLogoutDialog}
          onSubmit={handleLogout}
          content={{
            label: t('logout.title'),
            description: t('logout.desc'),
            icon: LogoutIcon,
          }}
        />
      </Toolbar>
      <Divider />
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop: string) => !['collapsed'].includes(prop),
})<{ collapsed: boolean }>(({ theme, collapsed }) => ({
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.drawer + 1,
  ...(collapsed && {
    marginLeft: 75,
    width: 'calc(100% - 75px)',
  }),
  ...(!collapsed && {
    marginLeft: 280,
    width: 'calc(100% - 280px)',
  }),
  [theme.breakpoints.down('lg')]: {
    marginLeft: 'revert',
    width: '100%',
  },
}));

export default Header;
