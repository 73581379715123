import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { IConfigPriceForLoad } from 'views/DispoAssistent/Booking/External/utils/types';
import type { TenderDetails } from 'types/tenderwizard';
import type { Equipment } from 'views/TenderWizard/External/utils/services';

interface IInitialState {
  isInitialized: boolean;
  countryId: number | null;
  locationId: number | null;
  activeStep: number;
  employeeId: number | null;
  companyId: number | null;
  bookingId: number | null;
  pricingForLoad: IConfigPriceForLoad | null;
  mode: 'create' | 'edit';
  km: number | null;
  travelTime: number | null;
  tenderDetails: TenderDetails | null;
  equipments: Equipment[];
}
const initialState: IInitialState = {
  activeStep: 0,
  isInitialized: false,
  countryId: null,
  locationId: null,
  employeeId: null,
  companyId: null,
  bookingId: null,
  pricingForLoad: null,
  mode: 'create',
  km: null,
  travelTime: null,
  tenderDetails: null,
  equipments: [],
};

const BookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    resetBooking: () => initialState,
    setIsInitialized(
      state,
      action: PayloadAction<IInitialState['isInitialized']>
    ) {
      state.isInitialized = action.payload;
    },
    setCountryId(state, action: PayloadAction<IInitialState['countryId']>) {
      state.countryId = action.payload;
    },
    setLocationId(state, action: PayloadAction<IInitialState['locationId']>) {
      state.locationId = action.payload;
    },
    setActiveStep(state, action: PayloadAction<IInitialState['activeStep']>) {
      state.activeStep = action.payload;
    },
    setEmployeeId(state, action: PayloadAction<IInitialState['employeeId']>) {
      state.employeeId = action.payload;
    },
    setCompanyId(state, action: PayloadAction<IInitialState['companyId']>) {
      state.companyId = action.payload;
    },
    setbookingId(state, action: PayloadAction<IInitialState['bookingId']>) {
      state.bookingId = action.payload;
    },
    setPricingForLoad(
      state,
      action: PayloadAction<IInitialState['pricingForLoad']>
    ) {
      state.pricingForLoad = action.payload;
    },
    setKm(state, action: PayloadAction<IInitialState['km']>) {
      state.km = action.payload;
    },
    setTravelTime(state, action: PayloadAction<IInitialState['travelTime']>) {
      state.travelTime = action.payload;
    },
    setTenderDetails(
      state,
      action: PayloadAction<IInitialState['tenderDetails']>
    ) {
      state.tenderDetails = action.payload;
    },
    setMode(state, action: PayloadAction<IInitialState['mode']>) {
      state.mode = action.payload;
    },
    setEquipments(state, action: PayloadAction<IInitialState['equipments']>) {
      state.equipments = action.payload;
    },
  },
});

export const {
  setIsInitialized,
  setLocationId,
  setCountryId,
  setTravelTime,
  setActiveStep,
  resetBooking,
  setEmployeeId,
  setCompanyId,
  setbookingId,
  setPricingForLoad,
  setKm,
  setTenderDetails,
  setMode,
  setEquipments,
} = BookingSlice.actions;

export default BookingSlice.reducer;
