import PrivateRoleRoute from 'components/PrivateRoleRoute';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Loadable from './Loadable';

// Tenders active
const Active = Loadable(lazy(() => import('views/Tenders/Active')));

// Tenders planned
const Planned = Loadable(lazy(() => import('views/Tenders/Planned')));

// Tenders selection
const Selection = Loadable(lazy(() => import('views/Tenders/Selection')));

// Tenders active
const ToAssign = Loadable(lazy(() => import('views/Tenders/ToAssign')));

// Tender withdrawn
const Withdrawn = Loadable(lazy(() => import('views/Tenders/Withdrawn')));

// Tender lost
const Lost = Loadable(lazy(() => import('views/Tenders/Lost')));

// Tender Details
const TenderActiveDetails = Loadable(lazy(() => import('views/Tenders/Active/ActiveDetails')));
const TenderPlannedDetails = Loadable(lazy(() => import('views/Tenders/Planned/PlannedDetails')));
const TenderToAssignDetails = Loadable(lazy(() => import('views/Tenders/ToAssign/ToAssignDetails')));
const TenderWithdrawnDetails = Loadable(lazy(() => import('views/Tenders/Withdrawn/WithdrawnDetails')));
const TenderLostDetails = Loadable(lazy(() => import('views/Tenders/Lost/LostDetails')));
const SelectionDetails = Loadable(lazy(() => import('views/Tenders/Selection/SelectionDetails')));

const TendersRoutes: RouteObject = {
  path: 'tenders',
  element: <PrivateRoleRoute companyRoles={['shipper', 'carrier','internal']} path="tender"><Outlet /></PrivateRoleRoute>,
  children: [
    { index: true, element: <Active /> },
    {
      path: 'active',
      children: [
        { index: true, element: <Active /> },
        { path: ':tenderId', element: <TenderActiveDetails /> },
      ],
    },
    {
      path: 'planned',
      children: [
        { index: true, element: <Planned /> },
        { path: ':tenderId', element: <TenderPlannedDetails /> },
      ],
    },
    {
      path: 'selection',
      children: [
        {
          index: true,
          element: (
            <PrivateRoleRoute companyRoles={['shipper', 'carrier']} path="tender">
              <Selection />
            </PrivateRoleRoute>
          ),
        },
        { path: ':tenderId', element: <SelectionDetails /> },
      ],
    },
    {
      path: 'to-assign',
      element: (
        <PrivateRoleRoute companyRoles={['internal']} path="tender">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <ToAssign /> },
        { path: ':tenderId', element: <TenderToAssignDetails /> },
      ],
    },
    {
      path: 'withdrawn',
      children: [
        { index: true, element: <Withdrawn /> },
        { path: ':tenderId', element: <TenderWithdrawnDetails /> },
      ],
    },
    {
      path: 'lost',
      element: (
        <PrivateRoleRoute companyRoles={['internal', 'carrier']} path="tender">
          <Outlet />
        </PrivateRoleRoute>
      ),
      children: [
        { index: true, element: <Lost /> },
        { path: ':tenderId', element: <TenderLostDetails /> },
      ],
    },
  ],
};

export default TendersRoutes;
