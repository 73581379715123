import AuthContext from 'contexts/AuthContext';
import { useContext } from 'react';

const useUser = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('Forgot to wrap component in AuthContext');
  }

  const { user, company } = authContext;

  if (!user || !company) {
    throw new Error('Unauthorized');
  }

  return { user, company };
};

export default useUser;
