import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LinkButton from 'components/LinkButton';
import Page from 'components/Page';
import { useTranslation } from 'react-i18next';

const PermissionDenied = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('errors.permissionDenied.title')}>
      <Container
        sx={{
          display: 'grid',
          placeContent: 'center',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AdminPanelSettingsIcon fontSize="large" color="error" />
          <Typography
            variant="h5"
            gutterBottom
            sx={{ mt: 1.5, fontWeight: 'medium' }}
            color="error"
          >
            {t('errors.permissionDenied.title')}
          </Typography>
          <Typography variant="subtitle2">
            {t('errors.permissionDenied.description')}
          </Typography>
          <LinkButton
            to="/"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            color="error"
            sx={{ mt: 4 }}
          >
            {t('errors.permissionDenied.back')}
          </LinkButton>
        </Box>
      </Container>
    </Page>
  );
};

export default PermissionDenied;
