import PrivateRoleRoute from 'components/PrivateRoleRoute';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import Loadable from './Loadable';

// Tender wizard
const TenderWizard = Loadable(lazy(() => import('views/TenderWizard')));

const CRMRoutes: RouteObject = {
  path: '/tender-wizard',
  element: (
    <PrivateRoleRoute companyRoles={['internal', 'shipper']} path="tender">
      <TenderWizard />
    </PrivateRoleRoute>
  ),
};

export default CRMRoutes;
