import type { EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { Status } from 'types/common';
import type {
  SearchAddress,
  SearchContact,
  TenderDetails,
} from 'types/tenderwizard';
import type { BookingLoadDetails } from 'views/DispoAssistent/Booking/External/utils/types';
import type { Equipment } from 'views/TenderWizard/External/utils/services';

interface TabItem {
  label: string;
  value: string;
  isBlankTab: boolean;
  tenderLoadId: number | null;
}

interface State {
  status: Status;
  isInitialized: boolean;
  freightCategory: 0 | 1 | 2 | 3 | null;
  tenderId: number | null;
  tenderDetails: TenderDetails | null;
  tab: string | null;
  contacts: SearchContact[];
  addresses: {
    pickupAddress: SearchAddress[];
    deliveryAddress: SearchAddress[];
  };
  equipments: Equipment[];
  tabs: EntityState<TabItem>;
  companyId: number | null;
  countryId: number | null;
  employeeId: number | null;
  locationId: number | null;
  mode: 'create' | 'edit';
  activeStep: number;
  bookingLoadDetails: BookingLoadDetails | null;
}

// Tabs
const tabsAdapter = createEntityAdapter<TabItem>({
  selectId: (tab) => tab.value,
});

const tabsSelectors = tabsAdapter.getSelectors<RootState>((state) => {
  return state.tenderWizard.tabs;
});

const initialState: State = {
  status: 'idle',
  isInitialized: false,
  freightCategory: null,
  tenderId: null,
  tenderDetails: null,
  contacts: [],
  addresses: {
    pickupAddress: [],
    deliveryAddress: [],
  },
  equipments: [],
  tab: null,
  tabs: tabsAdapter.getInitialState(),
  companyId: null,
  countryId: null,
  employeeId: null,
  locationId: null,
  mode: 'create',
  activeStep: 0,
  bookingLoadDetails: null,
};

const tenderWizardSlice = createSlice({
  name: 'tenderwizard',
  initialState,
  reducers: {
    resetTenderWizard: () => initialState,
    setFreightCategory(state, action: PayloadAction<State['freightCategory']>) {
      state.freightCategory = action.payload;
    },
    setTenderId(state, action: PayloadAction<State['tenderId']>) {
      state.tenderId = action.payload;
    },
    setTenderDetails(state, action: PayloadAction<State['tenderDetails']>) {
      state.tenderDetails = action.payload;
    },
    setTab(state, action: PayloadAction<State['tab']>) {
      state.tab = action.payload;
    },
    setContacts(state, action: PayloadAction<State['contacts']>) {
      state.contacts = action.payload;
    },
    setEquipments(state, action: PayloadAction<State['equipments']>) {
      state.equipments = action.payload;
    },
    setAddresses(state, action: PayloadAction<State['addresses']>) {
      state.addresses = action.payload;
    },
    setTabs(state, action: PayloadAction<TabItem[]>) {
      tabsAdapter.setAll(state.tabs, action.payload);
    },
    addTab(state, action: PayloadAction<TabItem>) {
      tabsAdapter.addOne(state.tabs, action.payload);
    },
    removeTab(state, action: PayloadAction<string>) {
      tabsAdapter.removeOne(state.tabs, action.payload);
    },
    updateTab(state, action: PayloadAction<TabItem>) {
      const { value, ...rest } = action.payload;
      tabsAdapter.updateOne(state.tabs, { id: value, changes: rest });
    },
    setCompanyId(state, action: PayloadAction<State['companyId']>) {
      state.companyId = action.payload;
    },
    setCountryId(state, action: PayloadAction<State['countryId']>) {
      state.countryId = action.payload;
    },
    setEmployeeId(state, action: PayloadAction<State['employeeId']>) {
      state.employeeId = action.payload;
    },
    setLocationId(state, action: PayloadAction<State['locationId']>) {
      state.locationId = action.payload;
    },
    setIsInitialized(state, action: PayloadAction<State['isInitialized']>) {
      state.isInitialized = action.payload;
    },
    setMode(state, action: PayloadAction<State['mode']>) {
      state.mode = action.payload;
    },
    setActiveStep(state, action: PayloadAction<State['activeStep']>) {
      state.activeStep = action.payload;
    },
    setBookingLoadDetails(
      state,
      action: PayloadAction<State['bookingLoadDetails']>
    ) {
      state.bookingLoadDetails = action.payload;
    },
  },
});

export const {
  setFreightCategory,
  setTenderId,
  setTenderDetails,
  setTab,
  setContacts,
  setAddresses,
  resetTenderWizard,
  setTabs,
  addTab,
  removeTab,
  updateTab,
  setCompanyId,
  setEmployeeId,
  setLocationId,
  setIsInitialized,
  setMode,
  setActiveStep,
  setCountryId,
  setBookingLoadDetails,
  setEquipments,
} = tenderWizardSlice.actions;

export { tabsSelectors };
export default tenderWizardSlice.reducer;
