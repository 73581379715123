import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
  tab: string;
}
const initialState: State = {
  tab: '',
};
const segmentSlice = createSlice({
  name: 'segment',
  initialState,
  reducers: {
    setTransitionPage(state, action: PayloadAction<State['tab']>) {
      state.tab = action.payload;
    },
  },
});

export const { setTransitionPage } = segmentSlice.actions;
export default segmentSlice.reducer;
