import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import GavelIcon from '@mui/icons-material/Gavel';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import TuneIcon from '@mui/icons-material/Tune';
import CarRentalIcon from '@mui/icons-material/CarRental';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import LogoutConfirmDialog from 'components/LogoutConfirmDialog';
import { COMPANY_ROLES } from 'constants/role';
import SignalrContext from 'contexts/SignalrContext';
import useAuth from 'hooks/useAuth';
import useMounted from 'hooks/useMounted';
import usePermissions from 'hooks/usePermissions';
import useUser from 'hooks/useUser';
import type { FC, ReactNode } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { getNotificationNumber } from 'services/crm/registration';
import type { CompanyRole, PermissionPath } from 'types/role';
import type { UserPermission } from 'types/user';
import sleep from 'utils/sleep';
import MenuItem from './MenuItem';
import Routing from 'constants/routing';

interface SectionItem {
  title: string;
  children?: SectionItem[];
  info?: () => JSX.Element | null;
  icon?: ReactNode;
  route?: string;
  path: PermissionPath;
  companyRoles: CompanyRole[];
}

const getSections = (
  t: TFunction,
  newRegistration: number,
  openDesktopSidebar: boolean
): SectionItem[] => [
  {
    title: t('Dispo-Assistent'),
    route: Routing.dispoAssistent,
    icon: <AutoFixHighIcon />,
    companyRoles: ['internal', 'shipper'],
    path: 'tender',
  },
  {
    title: 'Dashboard',
    route: '/dashboard',
    icon: <DashboardRoundedIcon />,
    companyRoles: ['internal', 'shipper', 'carrier'],
    path: '*',
  },
  {
    title: t('tenders.title.main'),
    route: '/tenders',
    icon: <GavelIcon />,
    companyRoles: ['internal', 'shipper', 'carrier'],
    path: '*',
    children: [
      {
        title: t('tenders.active.title.main'),
        route: '/tenders/active',
        companyRoles: ['internal', 'shipper', 'carrier'],
        path: 'tender',
      },
      {
        title: t('tenders.planned.title.main'),
        route: '/tenders/planned',
        companyRoles: ['internal', 'shipper'],
        path: 'tender',
      },
      {
        title: t('tenders.selection.title.main'),
        route: '/tenders/selection',
        companyRoles: ['shipper', 'carrier'],
        path: 'tender',
      },
      {
        title: t('tenders.toAssign.title.main'),
        route: '/tenders/to-assign',
        companyRoles: ['internal'],
        path: 'tender',
      },
      {
        title: t('tenders.withdrawn.title.main'),
        route: '/tenders/withdrawn',
        companyRoles: ['*'],
        path: 'tender',
      },
      {
        title: t('tenders.lost.title.main'),
        route: '/tenders/lost',
        companyRoles: ['internal', 'carrier'],
        path: 'tender',
      },
    ],
  },
  {
    title: t('transports.title'),
    route: '/transports',
    icon: <LocalShippingIcon />,
    companyRoles: ['internal', 'shipper', 'carrier'],
    path: '*',
    children: [
      {
        title: t('transports.inExecution.title.main'),
        route: '/transports/in-execution',
        companyRoles: ['*'],
        path: 'tender',
      },
      {
        title: t('transports.completed.title.main'),
        route: '/transports/completed',
        companyRoles: ['*'],
        path: 'tender',
      },
    ],
  },
  {
    title: t('renting.title'),
    route: '/renting',
    icon: <CarRentalIcon />,
    companyRoles: ['renter', 'rental'],
    path: '*',
    children: [
      {
        title: t('renting.preReservations.title'),
        route: '/renting/pre-reservations',
        companyRoles: ['renter', 'rental'],
        path: '*',
      },
      {
        title: t('renting.rentals.title'),
        route: '/renting/rentals',
        companyRoles: ['renter', 'rental'],
        path: '*',
      },
      {
        title: t('renting.closedRentals.title'),
        route: '/renting/closed-rentals',
        companyRoles: ['renter', 'rental'],
        path: '*',
      },
    ],
  },
  {
    title: t('documents.title'),
    route: '/documents',
    icon: <DescriptionIcon />,
    companyRoles: ['*'],
    path: '*',
    children: [
      {
        title: t('documents.transportConfirmations.title.main'),
        route: '/documents/transport-confirmations',
        companyRoles: ['*'],
        path: 'document',
      },
      {
        title: t('documents.deliveryNotes.title.main'),
        route: '/documents/delivery-notes',
        companyRoles: ['*'],
        path: 'document',
      },
      {
        title: t('documents.successCommissions.title.main'),
        route: '/documents/success-commissions',
        companyRoles: ['*'],
        path: 'document',
      },
      {
        title: t('documents.termsAndConditions.title.main'),
        route: '/documents/terms-conditions',
        companyRoles: ['shipper', 'carrier'],
        path: 'document',
      },
      {
        title: t('documents.rentalContracts.title.main'),
        route: '/documents/rental-contracts',
        companyRoles: ['rental', 'renter'],
        path: 'document',
      },
    ],
  },
  {
    title: t('companySettings.title'),
    route: '/company-settings',
    icon: <PermDataSettingIcon />,
    companyRoles: ['shipper', 'carrier', 'rental'],
    path: '*',
    children: [
      {
        title: t('companySettings.organisation.title'),
        route: '/company-settings/organisation',
        companyRoles: ['shipper', 'carrier', 'rental'],
        path: '*',
        children: [
          {
            title: t('companySettings.organisation.employees.title.main'),
            route: '/company-settings/organisation/employees',
            companyRoles: ['shipper', 'carrier', 'rental'],
            path: 'employee',
          },
          {
            title: t('companySettings.organisation.locations.title.main'),
            route: '/company-settings/organisation/locations',
            companyRoles: ['shipper', 'carrier', 'rental'],
            path: 'location',
          },
          {
            title: t('companySettings.organisation.addresses.title.main'),
            route: '/company-settings/organisation/addresses',
            companyRoles: ['shipper', 'rental'],
            path: 'address',
          },
          {
            title: t('companySettings.organisation.contacts.title.main'),
            route: '/company-settings/organisation/contacts',
            companyRoles: ['shipper', 'rental'],
            path: 'contact',
          },
          {
            title: t('companySettings.organisation.company.title.main'),
            route: '/company-settings/organisation/company',
            companyRoles: ['shipper', 'carrier', 'rental'],
            path: 'company',
          },
        ],
      },
      {
        title: t('companySettings.vehicle.loads.title.main'),
        route: '/company-settings/construction-machinery-equipment',
        companyRoles: ['shipper', 'rental'],
        path: 'load',
      },
      {
        title: t('companySettings.bidderLists.title.main'),
        route: '/company-settings/bidder-lists',
        companyRoles: ['shipper', 'rental'],
        path: '*',
      },
    ],
  },
  {
    title: t('crm.title'),
    route: '/crm',
    icon:
      !openDesktopSidebar && newRegistration ? (
        <Badge variant="dot" color="primary">
          <PeopleIcon />
        </Badge>
      ) : (
        <PeopleIcon />
      ),
    companyRoles: ['internal'],
    path: '*',
    info: () => {
      if (!openDesktopSidebar) {
        return null;
      }

      if (newRegistration) {
        return (
          <Chip
            label={newRegistration}
            color="primary"
            size="small"
            sx={{ borderRadius: 'revert' }}
          />
        );
      }

      return null;
    },
    children: [
      {
        title: t('crm.newRegistrations.title.main'),
        route: '/crm/registrations',
        companyRoles: ['internal'],
        path: 'newRegistration',
        info: () => {
          if (newRegistration) {
            return (
              <Chip
                label={newRegistration}
                color="primary"
                size="small"
                sx={{ borderRadius: 'revert' }}
              />
            );
          }
          return null;
        },
      },
      {
        title: t('crm.accounts.title.main'),
        route: '/crm/accounts',
        companyRoles: ['internal'],
        path: 'account',
      },
      {
        title: t('crm.users.title.main'),
        route: '/crm/users',
        companyRoles: ['internal'],
        path: 'contact',
      },
      {
        title: t('crm.segments.title.main'),
        route: '/crm/segments',
        companyRoles: ['internal'],
        path: 'segment',
      },
      {
        title: t('documents.termsAndConditions.title.main'),
        route: '/crm/terms-conditions',
        companyRoles: ['internal'],
        path: 'term',
      },
      {
        title: t('crm.dataProtection.title.main'),
        route: '/crm/data-protection',
        companyRoles: ['internal'],
        path: '*',
        children: [
          {
            title: t('crm.dataProtection.blacklist.title.main'),
            route: '/crm/data-protection/black-list',
            companyRoles: ['internal'],
            path: '*',
          },
        ],
      },
    ],
  },
  {
    title: t('configurations.title'),
    route: '/configurations',
    icon: <TuneIcon />,
    companyRoles: ['internal'],
    path: '*',
    children: [
      {
        title: t('configurations.organisation.title'),
        route: '/configurations/organisation',
        companyRoles: ['internal'],
        path: '*',
        children: [
          {
            title: t('companySettings.organisation.employees.title.main'),
            route: '/configurations/organisation/employees',
            companyRoles: ['internal'],
            path: 'internal.employee',
          },
          {
            title: t('companySettings.organisation.company.title.main'),
            route: '/configurations/organisation/company',
            companyRoles: ['internal'],
            path: 'internal.company',
          },
        ],
      },
      {
        title: 'Markets',
        route: '/configurations/markets',
        companyRoles: ['internal'],
        path: '*',
        children: [
          {
            title: t('configurations.markets.countries.title.main'),
            route: '/configurations/markets/countries',
            companyRoles: ['internal'],
            path: '*',
          },
        ],
      },
      {
        title: t('configurations.loadsAndCategories.title'),
        route: '/configurations/loads-categories',
        companyRoles: ['internal'],
        path: '*',
        children: [
          {
            title: t('configurations.loadsAndCategories.brands.title.main'),
            route: '/configurations/loads-categories/brands',
            companyRoles: ['internal'],
            path: 'brand',
          },
          {
            title: t(
              'configurations.loadsAndCategories.productFamilies.title.main'
            ),
            route: '/configurations/loads-categories/product-families',
            companyRoles: ['internal'],
            path: 'productFamily',
          },
        ],
      },
      {
        title: t('configurations.security.title'),
        route: '/configurations/security',
        companyRoles: ['internal'],
        path: '*',
        children: [
          {
            title: t('configurations.security.roles.title.main'),
            route: '/configurations/security/roles-permissions',
            companyRoles: ['internal'],
            path: '*',
          },
          {
            title: t('configurations.security.authentication.title'),
            route: '/configurations/security/authentication',
            companyRoles: ['internal'],
            path: 'authentication',
          },
          {
            title: t('configurations.security.maintenance.title.main'),
            route: '/configurations/security/maintenance',
            companyRoles: ['internal'],
            path: 'maintenance',
          },
          {
            title: t('configurations.logs.title'),
            route: '/configurations/security/logs',
            companyRoles: ['internal'],
            path: '*',
            children: [
              {
                title: t('configurations.logs.constructionMachineLogs.title'),
                route:
                  '/configurations/security/logs/construction-machine-logs',
                companyRoles: ['internal'],
                path: '*',
              },
              {
                title: t('configurations.security.logFiles.title'),
                route: '/configurations/security/logs/sign-in-logs',
                companyRoles: ['internal'],
                path: 'logFiles',
              },
            ],
          },
        ],
      },
      {
        title: t('Notifications'),
        route: '/configurations/notifications',
        companyRoles: ['internal'],
        path: '*',
      },
    ],
  },
];

interface MenuItemsProps {
  items: SectionItem[];
  pathname: string;
  level?: number;
  roles: CompanyRole[];
  collapsed: boolean;
  userPermissions: UserPermission[];
  allPermission: UserPermission[];
  openSidebar: boolean;
}

const MenuItems = (props: MenuItemsProps) => {
  const {
    level = 0,
    items,
    roles,
    pathname,
    collapsed,
    openSidebar,
    userPermissions,
    allPermission,
  } = props;

  const itemsFiltered =
    level === 0
      ? items.filter(({ companyRoles, path }) => {
          const hasPermission =
            path === '*'
              ? true
              : userPermissions.some(
                  (permission) => permission.code === path
                ) &&
                allPermission.some((permission) => permission.code === path);
          const companyRestricted = companyRoles.includes('*')
            ? true
            : roles.some((role) => companyRoles.includes(role));
          return hasPermission && companyRestricted;
        })
      : items;

  return (
    <List disablePadding>
      {itemsFiltered.reduce((acc: JSX.Element[], item) => {
        const { children, icon, info, route, title } = item;
        const key = `${title}-${level}`;
        const partialMatch = pathname.startsWith(String(route));
        // const exactMatch = pathname === item.path;
        if (children) {
          const items = children.filter((item) => {
            const { companyRoles, path } = item;
            const hasPermission =
              path === '*'
                ? true
                : userPermissions.some(
                    (permission) => permission.code === path
                  ) &&
                  allPermission.some((permission) => permission.code === path);
            const companyRestricted = companyRoles.includes('*')
              ? true
              : roles.some((role) => companyRoles.includes(role));
            return hasPermission && companyRestricted;
          });
          acc.push(
            <MenuItem
              key={key}
              icon={icon}
              info={info}
              route={route}
              title={title}
              level={level}
              open={partialMatch}
              active={partialMatch}
              collapsed={collapsed}
              openSidebar={openSidebar}
            >
              {MenuItems({
                level: level + 1,
                items,
                pathname,
                roles,
                collapsed,
                openSidebar,
                userPermissions,
                allPermission,
              })}
            </MenuItem>
          );
        } else {
          acc.push(
            <MenuItem
              key={key}
              icon={icon}
              info={info}
              route={route}
              title={title}
              level={level}
              active={partialMatch}
              collapsed={collapsed}
              openSidebar={openSidebar}
            />
          );
        }

        return acc;
      }, [])}
    </List>
  );
};

interface Props {
  collapsed: boolean;
  openSidebar: boolean;
}

const Sidebar: FC<Props> = (props) => {
  const { collapsed, openSidebar } = props;
  const location = useLocation();
  const { logout } = useAuth();
  const { user, company } = useUser();
  const allPermission = usePermissions();
  const { t } = useTranslation();
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);
  const mounted = useMounted();

  const [newRegistration, setNewRegistration] = useState<number>(0);
  const { refreshNewRegistration } = useContext(SignalrContext);

  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    if (mounted.current) {
      setOpenLogoutDialog(false);
    }
  };

  const handleLogout = async () => {
    await sleep(350);
    logout();
  };

  useEffect(() => {
    if (!user) return;
    const {
      userRole: { isInternal },
    } = user;
    if (isInternal) {
      getNotificationNumber()
        .then((response) => {
          const { data } = response;
          if (mounted.current) {
            setNewRegistration(data?.newRegistration || 0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [refreshNewRegistration, mounted, user]);

  const sections = useMemo(
    () => getSections(t, newRegistration, collapsed),
    [t, newRegistration, collapsed]
  );

  if (!user || !company) {
    return null;
  }

  const { companyRoleIdList } = company;
  const {
    userRole: { permissions: userPermissions },
  } = user;
  const roles = companyRoleIdList.map((roleId) => COMPANY_ROLES[roleId]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MenuItems
        items={sections}
        pathname={location.pathname}
        roles={roles}
        collapsed={collapsed}
        openSidebar={openSidebar}
        userPermissions={userPermissions}
        allPermission={allPermission}
      />
      <List disablePadding>
        <ListItem disableGutters disablePadding>
          <Button
            variant="text"
            startIcon={<LogoutIcon />}
            onClick={handleOpenLogoutDialog}
            sx={{
              color: 'text.secondary',
              justifyContent: 'flex-start',
              p: 1.5,
              pl: 3.5,
              textAlign: 'left',
              width: '100%',
              fontWeight: 'medium',
            }}
          >
            {!collapsed && t('logout.title')}
          </Button>
        </ListItem>
      </List>
      <LogoutConfirmDialog
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
        onSubmit={handleLogout}
        content={{
          label: t('logout.title'),
          description: t('logout.desc'),
          icon: LogoutIcon,
        }}
      />
    </Box>
  );
};

export default Sidebar;
