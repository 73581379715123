import { styled } from '@mui/material/styles';
import ShowMoreText from 'react-show-more-text';
import type { FC, ReactNode } from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ShowMoreTextWrapProps {
  children: ReactNode;
  lines: number;
  truncatedEndingComponent?: ReactNode;
  style?: {};
  width?: number | undefined;
  sx?: SxProps<Theme>;
}

const ShowMoreTextWrap: FC<ShowMoreTextWrapProps> = (props) => {
  const { t } = useTranslation();
  const {
    lines,
    children,
    truncatedEndingComponent = '... ',
    ...other
  } = props;

  return (
    <ShowMoreTextWrapRoot
      lines={lines}
      more={t('form.seeMore')}
      less={t('form.showLess')}
      expanded={false}
      anchorClass="tp-show-more-less-clickable"
      truncatedEndingComponent={truncatedEndingComponent}
      {...other}
    >
      {children}
    </ShowMoreTextWrapRoot>
  );
};

const ShowMoreTextWrapRoot = styled(ShowMoreText)(({ theme }) => {
  return {
    '& .tp-show-more-less-clickable': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      cursor: 'pointer',
      textDecoration: 'underline',

      '&:hover': {
        textDecoration: 'none',
      },
    },
  };
});

export default ShowMoreTextWrap;
