import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ControllerTextField from 'components/Form/ControllerTextField';
import LinkButton from 'components/LinkButton';
import Page from 'components/Page';
import useNotification from 'hooks/useNotification';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { forgotPasswordConfirm } from 'services/shared/auth';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('schema.required')
    .trim('schema.trim')
    .strict(true)
    .default(''),
  retypeNewPassword: yup
    .string()
    .required('schema.required')
    .trim('schema.trim')
    .default('')
    .oneOf([yup.ref('newPassword')], 'schema.passwordDoesNotMatch'),
});

interface ForgotPasswordFormData {
  newPassword: string;
  retypeNewPassword: string;
}

const ResetPassword = () => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: validationSchema.getDefault(),
  });

  const setNotification = useNotification();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const onSubmit = async (data: ForgotPasswordFormData) => {
    const [token, email] = location.search.split('?Token=')[1].split('&Email=');
    setLoading(true);
    try {
      if (email && token) {
        const res = await forgotPasswordConfirm({
          email,
          token,
          password: data.newPassword,
        });
        if (res.success) {
          setNotification({
            message: t('user.changePassword.response.update.success'),
            severity: 'success',
          });
          navigate('/login');
        } else {
          if (res.messageCode === 'MSG0103') {
            setNotification({
              error: t(`MSG.${res.messageCode}`),
            });
          } else {
            setNotification({
              error: t('user.changePassword.response.update.error'),
            });
          }
        }
      }
    } catch (error) {
      setNotification({
        error: t('user.changePassword.response.update.error'),
      });
    } finally {
      setLoading(false);
    }
  };

  console.log(errors);

  return (
    <Page title={`${t('resetPassword.title')}`}>
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Paper sx={{ p: 3, width: '100%' }}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Typography
              variant="h4"
              sx={{ mb: 2, fontWeight: 600, userSelect: 'none' }}
            >
              DISPOREON
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Typography variant="body1">{t('resetPassword.desc')}</Typography>
            <Box sx={{ flexGrow: 1, mt: 3 }}>
              <Box>
                <ControllerTextField
                  name="newPassword"
                  control={control}
                  autoFocus
                  margin="dense"
                  placeholder={t('form.newPassword')}
                  type="password"
                />
              </Box>
              <Box>
                <ControllerTextField
                  name="retypeNewPassword"
                  control={control}
                  autoFocus
                  margin="dense"
                  placeholder={t('form.retypeNewPassword')}
                  type="password"
                />
              </Box>
            </Box>
            <Divider sx={{ my: 3 }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <LinkButton to="/login">{t('common.action.cancel')}</LinkButton>
              <LoadingButton
                loading={loading}
                startIcon={<CheckIcon />}
                loadingPosition="start"
                type="submit"
              >
                {t('saveNewPassword')}
              </LoadingButton>
            </Box>
          </form>
        </Paper>
      </Container>
    </Page>
  );
};

export default ResetPassword;
