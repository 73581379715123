const baseURL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : 'http://localhost:3000';

const hubSocketURL =
  process.env.REACT_APP_HUB_URL || 'https://localhost:44385/hubs/notifications';

const version = process.env.REACT_APP_VERSION;

const baseUrlImage =
  process.env.REACT_APP_BASE_URL_IMAGE || 'http://210.245.85.229:9967/';

export { baseURL, hubSocketURL, version, baseUrlImage };
