import PrivateRoleRoute from 'components/PrivateRoleRoute';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Loadable from './Loadable';

const Renting = Loadable(lazy(() => import('views/Renting')));
const PreReservations = Loadable(
  lazy(() => import('views/Renting/PreReservations'))
);
const Rentals = Loadable(lazy(() => import('views/Renting/Rentals')));
const ClosedRentals = Loadable(
  lazy(() => import('views/Renting/ClosedRentals'))
);
const RentingDetails = Loadable(
  lazy(() => import('views/Renting/RentingDetails'))
);

const RentingRoutes: RouteObject = {
  path: 'renting',
  element: (
    <PrivateRoleRoute companyRoles={['renter', 'rental']} path="*">
      <Outlet />
    </PrivateRoleRoute>
  ),
  children: [
    { index: true, element: <Rentals /> },
    { path: ':loadId', element: <Renting /> },
    {
      path: 'pre-reservations',
      children: [
        { index: true, element: <PreReservations /> },
        { path: ':rentId', element: <RentingDetails to='/renting/pre-reservations' label='Pre-reservations' /> },
      ],
    },
    {
      path: 'rentals',
      children: [
        { index: true, element: <Rentals /> },
        { path: ':rentId', element: <RentingDetails to='/renting/rentals' label='Rentals' /> },
      ],
    },
    {
      path: 'closed-rentals',
      children: [
        { index: true, element: <ClosedRentals /> },
        { path: ':rentId', element: <RentingDetails to='/renting/closed-rentals' label='Closed-rentals' /> },
      ],
    },
  ],
};

export default RentingRoutes;
