import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface State {
  onRefreshToken: string | null;
}

const initialState: State = {
  onRefreshToken: null,
};

const signalrSlice = createSlice({
  name: 'signalr',
  initialState,
  reducers: {
    onRefreshToken(state, action: PayloadAction<State['onRefreshToken']>) {
      state.onRefreshToken = action.payload;
    },
  },
});

export const { onRefreshToken } = signalrSlice.actions;
export default signalrSlice.reducer;
