import { combineReducers } from '@reduxjs/toolkit';
import vehicleTruckReducer from 'slices/companySettings/vehicles/load';
import vehicleLoadReducer from 'slices/companySettings/vehicles/truck';
import configurationsSecurityReducer from 'slices/configurations/security';
import configurationsCrmReducer from 'slices/crm';
import tenderWizardReducer from 'slices/tenderWizard';
import termsConditionsReducer from 'slices/termsConditions';
import signalrReducer from 'slices/signalr';
import emailEditorReducer from 'slices/emailEditor';
import rentingReducer from 'slices/renting';
import segmentReducer from 'slices/segment';
import notificationsReducer from 'slices/notifications';
import bookingReducer from 'slices/booking';

const rootReducer = combineReducers({
  configurations: combineReducers({
    security: configurationsSecurityReducer,
    crm: configurationsCrmReducer,
  }),
  companySettings: combineReducers({
    vehicle: combineReducers({
      truck: vehicleLoadReducer,
      load: vehicleTruckReducer,
    }),
  }),
  tenderWizard: tenderWizardReducer,
  termsConditions: termsConditionsReducer,
  signalr: signalrReducer,
  emailEditor: emailEditorReducer,
  renting: rentingReducer,
  segment: segmentReducer,
  notifications: notificationsReducer,
  booking: bookingReducer,
});

export default rootReducer;
