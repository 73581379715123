import type { EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { Status } from 'types/common';
import type { LogFile } from 'types/configurations/security';
import { UserRole } from 'types/configurations/security';
import { getLogFiles } from './logfiles';
import { getRoles } from './rolesAndPermissions';

interface State {
  status: Status;
  logFiles: EntityState<LogFile>;
  roles: EntityState<UserRole>;
}

// Log files
const logFilesAdapter = createEntityAdapter<LogFile>({
  selectId: (logFile) => logFile.id,
});
const logFilesSelectors = logFilesAdapter.getSelectors<RootState>((state) => {
  return state.configurations.security.logFiles;
});

// Roles and permissions
const rolesAdapter = createEntityAdapter<UserRole>({
  selectId: (role) => role.id,
});
const rolesSelectors = rolesAdapter.getSelectors<RootState>((state) => {
  return state.configurations.security.roles;
});

const initialState: State = {
  status: 'idle',
  roles: rolesAdapter.getInitialState(),
  logFiles: logFilesAdapter.getInitialState(),
};

const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getLogFiles
    builder.addCase(getLogFiles.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getLogFiles.fulfilled, (state, action) => {
      logFilesAdapter.setAll(state.logFiles, action.payload.logFiles);
      state.status = 'fulfilled';
    });
    builder.addCase(getLogFiles.rejected, (state) => {
      state.status = 'rejected';
    });

    // getRoles
    builder.addCase(getRoles.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      rolesAdapter.setAll(state.roles, action.payload.roles);
      state.status = 'fulfilled';
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.status = 'rejected';
    });
  },
});

// Adapter & selectors
export { logFilesAdapter, logFilesSelectors, rolesSelectors };

// export const {} = securitySlice.actions;
export default securitySlice.reducer;
