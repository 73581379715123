import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import type { ListItemProps } from '@mui/material/ListItem';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import Routing from 'constants/routing';
import useDerivedState from 'hooks/useDerivedState';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';
import type { MouseEvent } from 'types/react';
import SubMenuItem from './SubMenuItem';

interface Props extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  level: number;
  icon?: ReactNode;
  info?: () => JSX.Element | null;
  open?: boolean;
  route?: string;
  title: string;
  collapsed: boolean;
  openSidebar: boolean;
}

const MenuItem: FC<Props> = (props) => {
  const {
    active,
    children,
    level,
    icon,
    info: Info,
    open,
    route,
    title,
    collapsed,
    openSidebar,
    ...other
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [expanded, setExpanded] = useDerivedState<boolean>(Boolean(open));

  const { pathname } = useLocation();
  const prevPathName = usePrevious(pathname);

  useEffect(() => {
    if (prevPathName !== pathname && collapsed) {
      setAnchorEl(null);
    }
  }, [pathname, collapsed, prevPathName]);

  const handleClickMenu: MouseEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOnToggle = () => {
    setExpanded((expanded) => !expanded);
  };

  let paddingLeft = 8 * 3;

  if (level > 0) {
    paddingLeft = !collapsed ? 40 + 8 * level : 16 + 8 * level;
  }

  // Branch minimize
  if (level === 0 && collapsed) {
    if (children) {
      return (
        <ListItem
          disableGutters
          disablePadding
          sx={{
            display: 'block',
            borderLeft: 4,
            borderColor: 'transparent',
            ...(expanded && {
              borderColor: 'primary.main',
            }),
          }}
          {...other}
        >
          <Tooltip
            title={title}
            placement="right"
            componentsProps={{ tooltip: { sx: { borderRadius: 0 } } }}
          >
            <Button
              onClick={handleClickMenu}
              startIcon={icon}
              sx={{
                color: 'text.secondary',
                fontWeight: 'medium',
                justifyContent: 'flex-start',
                pl: `${paddingLeft}px`,
                pr: 2,
                py: 2,
                textAlign: 'left',
                textTransform: 'none',
                width: '100%',
                borderRadius: 0,
                '&:hover': {
                  bgcolor: 'transparent',
                },
                ...(active && {
                  color: 'text.primary',
                  bgcolor: 'action.selected',
                  '&:hover': {
                    bgcolor: 'action.selected',
                  },
                }),
              }}
              variant="text"
            />
          </Tooltip>
          <SubMenuItem
            anchorEl={anchorEl}
            handleClose={handleCloseMenu}
            open={Boolean(anchorEl)}
          >
            {children}
          </SubMenuItem>
        </ListItem>
      );
    } else {
      return (
        <ListItem disableGutters disablePadding sx={{ display: 'flex' }}>
          <Tooltip
            title={title}
            placement="right"
            componentsProps={{ tooltip: { sx: { borderRadius: 0 } } }}
          >
            <Button
              startIcon={icon}
              sx={{
                color: 'text.secondary',
                justifyContent: 'flex-start',
                pl: `${paddingLeft}px`,
                pr: 2,
                py: 1.5,
                textAlign: 'left',
                textTransform: 'none',
                width: '100%',
                fontWeight: 'medium',
                borderRadius: 0,
                '&:hover': {
                  bgcolor: 'transparent',
                },
                ...(active && {
                  bgcolor: 'action.selected',
                  '&:hover': {
                    bgcolor: 'action.selected',
                  },
                }),
                ...(route === Routing.dispoAssistent && {
                  bgcolor: 'primary.main',
                  color: 'common.white',
                  '&:hover': {
                    bgcolor: 'primary.main',
                    color: 'common.white',
                  },
                }),
              }}
              {...(route && {
                component: RouterLink,
                to: route,
              })}
              variant="text"
            />
          </Tooltip>
        </ListItem>
      );
    }
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        disablePadding
        sx={{
          display: 'block',
          ...(level === 0 && {
            borderLeft: 4,
            borderColor: 'transparent',
            ...(expanded && {
              borderColor: 'primary.main',
            }),
          }),
        }}
        {...other}
      >
        <Button
          endIcon={
            expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowLeftIcon />
          }
          onClick={handleOnToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: 2,
            py: 1.5,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            borderRadius: 0,
            '&:hover': {
              bgcolor: 'transparent',
            },
            ...(active && {
              color: 'text.primary',
            }),
          }}
          variant="text"
        >
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            {title}
            <Box sx={{ pl: 2 }}>{Info && <Info />}</Box>
          </Box>
        </Button>
        <Collapse in={expanded}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem disableGutters disablePadding sx={{ display: 'flex' }}>
      <Button
        startIcon={icon}
        sx={{
          color: 'text.secondary',
          justifyContent: 'flex-start',
          pl: `${paddingLeft}px`,
          pr: 2,
          py: 1.5,
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
          fontWeight: 'medium',
          borderRadius: 0,
          '&:hover': {
            bgcolor: 'transparent',
          },
          ...(active && {
            bgcolor: 'action.selected',
            '&:hover': {
              bgcolor: 'action.selected',
            },
          }),
          ...(route === Routing.dispoAssistent && {
            bgcolor: 'primary.main',
            color: 'common.white',
            '&:hover': {
              bgcolor: 'primary.main',
              color: 'common.white',
            },
          }),
        }}
        {...(route && {
          component: RouterLink,
          to: route,
        })}
        variant="text"
      >
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          {title}
          <Box sx={{ pl: 2 }}>{Info && <Info />}</Box>
        </Box>
      </Button>
    </ListItem>
  );
};

export default MenuItem;
