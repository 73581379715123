import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { hubSocketURL } from 'config';
import useAuth from 'hooks/useAuth';
import useForceUpdate from 'hooks/useForceUpdate';
import useRefresh from 'hooks/useRefresh';
import type { FC } from 'react';
import { createContext, useEffect, useState } from 'react';
import LocalStorage from 'utils/LocalStorage';

export interface SignalrContextValue {
  refreshNewRegistration: object;
  rerenderPublishTerm: object;
  rendererTerms: object;
}

export const SignalrContext = createContext<SignalrContextValue>({
  refreshNewRegistration: {},
  rerenderPublishTerm: {},
  rendererTerms: {},
});

if (process.env.NODE_ENV === 'development') {
  SignalrContext.displayName = 'SignalrContext';
}

const SignalrProvider: FC = ({ children }) => {
  const { user } = useAuth();
  const [rerenderPublishTerm, forceUpdatePublishTerm] = useForceUpdate();
  const [rendererTerms, forceUpdateTerms] = useForceUpdate();
  const [refreshNewRegistration, setRefreshNewRegistration] = useRefresh();
  const [connection, setConnection] = useState<null | HubConnection>(null);

  //connect to notification hub
  useEffect(() => {
    if (user) {
      const accessToken = LocalStorage.get('accessToken');
      const connect = new HubConnectionBuilder()
        .withUrl(hubSocketURL, {
          accessTokenFactory: () => accessToken,
        })
        .withAutomaticReconnect()
        .build();

      setConnection(connect);
    }
  }, [user]);

  //listening hub and close when logout
  useEffect(() => {
    if (connection && user) {
      connection
        .start()
        .then(() => {
          connection.on('ReceiveMessage', (message) => {
            //listening group in hub, only use for test purpose
          });
          connection.on('NewTermPublish', () => {
            // hub to publish term when admin active term
            forceUpdatePublishTerm();
          });
          connection.on('NewRegistrationChange', () => {
            // hub to listening admin active new registration
            setRefreshNewRegistration();
          });
          connection.on('TermFileCreated', () => {
            // hub to listening progress create termPDF
            forceUpdateTerms();
          });
          connection.on('ChangeCompanyRole', () => {
            console.log('Company Role Changed');
          });
          connection.on('ChangeUserRole', () => {
            console.log('User Role Changed');
          });
        })
        .catch((error) => console.log(error));
    } else if (connection && !user) {
      connection.stop();
    }
  }, [
    connection,
    user,
    forceUpdatePublishTerm,
    forceUpdateTerms,
    setRefreshNewRegistration,
  ]);

  return (
    <SignalrContext.Provider
      value={{
        rerenderPublishTerm,
        rendererTerms,
        refreshNewRegistration,
      }}
    >
      {children}
    </SignalrContext.Provider>
  );
};

export { SignalrContext as default, SignalrProvider };
