import type { CompanyRole } from 'types/role';

const COMPANY_ROLES: Record<number, CompanyRole> = {
  2: 'shipper',
  3: 'carrier',
  4: 'internal',
  5: 'rental',
  6: 'renter',
};

export { COMPANY_ROLES };
