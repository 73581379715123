import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListRoles } from 'services/configurations/security/rolesAndPermissions';
import type { UserRoles } from 'types/configurations/security';

interface Params {
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  searchText: string;
}

export interface UserListRoles {
  roles: UserRoles;
}
const getRoles = createAsyncThunk<UserListRoles, Params>(
  'configurations/security/getListRoles',
  async (params) => {
    const { data } = await getListRoles(params);
    return { roles: data || [] };
  }
);

export { getRoles };
