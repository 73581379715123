import type { CommonParams, CommonResponse, Response } from 'types/common';
import type {
  UserPermissions,
  UserRole,
  UserRoles,
} from 'types/configurations/security';
import HttpClient from 'utils/HttpClient';

const getListRoles = (params: CommonParams) => {
  return HttpClient.post<CommonParams, Response<UserRoles>>(
    '/shared/MasterData/ListRoles',
    params
  );
};

// Get all permissions
const getAllPermissions = () => {
  return HttpClient.get<null, CommonResponse<UserPermissions>>(
    '/shared/MasterData/ListAllPermissions'
  );
};

const getRoleDetails = (roleId: number) => {
  return HttpClient.get<null, Response<UserRole>>(
    '/shared/MasterData/GetRole',
    { params: { roleId } }
  );
};

export { getListRoles, getAllPermissions, getRoleDetails };
