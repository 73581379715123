import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface State {
  fromDate: string | null;
  toDate: string | null;
}

const initialState: State = {
  fromDate: null,
  toDate: null,
};

const rentingSlice = createSlice({
  name: 'renting',
  initialState,
  reducers: {
    setDates(
      state,
      action: PayloadAction<{
        fromDate: State['fromDate'];
        toDate: State['toDate'];
      }>
    ) {
      const { fromDate, toDate } = action.payload;
      state.fromDate = fromDate;
      state.toDate = toDate;
    },
  },
});

export const { setDates } = rentingSlice.actions;
export default rentingSlice.reducer;
