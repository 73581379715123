import type { CommonParams, CommonResponse } from 'types/common';
import type { LogFile } from 'types/configurations/security';
import HttpClient from 'utils/HttpClient';

const getAuthLogFiles = (params: CommonParams) => {
  return HttpClient.post<typeof params, CommonResponse<LogFile[]>>(
    '/admin/configuration/Log/AuthLogs',
    params
  );
};

// Export log files to excel
interface ExportLogFilesResponse {
  data: string;
  headers: {
    'content-disposition': string;
    'content-length': string;
    'content-type': string;
  };
}
const exportLogFilesToExcel = (params: CommonParams) => {
  return HttpClient.pull<typeof params, ExportLogFilesResponse>(
    '/admin/configuration/Log/Export',
    params,
    { responseType: 'blob' }
  );
};

export { getAuthLogFiles, exportLogFilesToExcel };
