import useUser from 'hooks/useUser';
import { createContext, FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RatingFormWrapper from 'views/RatingForm/pages/RatingFormWrapper';
import {
  apiCheckConditionUserRateTransopra,
  apiCheckShipperRateCarriersByUser,
} from 'views/RatingForm/services';
import { IRatingList } from 'views/RatingForm/utils/types';

type TInitContext = {
  ratingList: IRatingList[];
  totalRating: number;
  currentRating: IRatingList | null;
  onCheckCondition: Function;
};

const initState = {
  ratingList: [],
  totalRating: 0,
  currentRating: null,
  onCheckCondition: () => {},
};

export const RatingContext = createContext<TInitContext>(initState);

const RatingProvider: FC = (props) => {
  const [isSkip, setIsSkip] = useState<boolean>(false);
  const [totalRating, setTotalRating] = useState<number>(0);
  const [ratingList, setRatingList] = useState<IRatingList[]>([]);
  const [currentRating, setCurrentRating] = useState<IRatingList | null>(null);

  const { user } = useUser();
  const { isInternal } = user;

  const location = useLocation();

  const onCheckCondition = () => {
    Promise.all([
      apiCheckShipperRateCarriersByUser(),
      apiCheckConditionUserRateTransopra(),
    ])
      .then((response) => {
        const [{ data: carriers }, isRateTranspora] = response;

        const newCarrierRatingList: IRatingList[] =
          carriers?.map((carrier) => {
            return {
              type: 'carrier',
              carrierId: carrier.carrierId,
              companyName: carrier.companyName,
              transportCompleted: carrier.transportCompleted,
            };
          }) || [];

        if (isRateTranspora) {
          const ratingList: IRatingList[] = [
            ...newCarrierRatingList,
            {
              type: 'transpora',
              carrierId: 0,
              companyName: 'Transpora',
              transportCompleted: 0,
            },
          ];

          setRatingList(ratingList);
          setTotalRating(ratingList.length);
          setCurrentRating(ratingList[0]);
        } else {
          setRatingList(newCarrierRatingList);
          setTotalRating(newCarrierRatingList.length);
          setCurrentRating(newCarrierRatingList[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSkip = () => {
    setIsSkip(true);
  };

  useEffect(() => {
    if (isInternal || isSkip) return;
    onCheckCondition();
  }, [location, isInternal, isSkip]);

  return (
    <RatingContext.Provider
      value={{
        ratingList: ratingList,
        totalRating: totalRating,
        currentRating: currentRating,
        onCheckCondition: onCheckCondition,
      }}
    >
      {props.children}
      {ratingList.length > 0 && currentRating && totalRating > 0 ? (
        <RatingFormWrapper
          totalRating={totalRating}
          ratingList={ratingList}
          currentRating={currentRating}
          setCurrentRating={setCurrentRating}
          setTotalRating={setTotalRating}
          setRatingList={setRatingList}
          onSkip={handleSkip}
        />
      ) : null}
    </RatingContext.Provider>
  );
};

export default RatingProvider;
