import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { getNotificationByUserId } from 'services/shared/Notification';
import type { FilterNotificationsParams } from 'services/shared/Notification';

interface State {
  isTabAllNotifications: boolean;
  totalUnreadNotifications: number;
}

const initialState: State = {
  isTabAllNotifications: false,
  totalUnreadNotifications: 0,
};

export const getTotalUnreadNotifications = createAsyncThunk<
  number,
  FilterNotificationsParams
>('Notifications/getTotalUnreadNotifications', async (params) => {
  const { data } = await getNotificationByUserId(params);
  return data?.unread || 0;
});

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setTabAllNotifications(
      state,
      action: PayloadAction<State['isTabAllNotifications']>
    ) {
      state.isTabAllNotifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTotalUnreadNotifications.fulfilled, (state, actions) => {
      state.totalUnreadNotifications = actions.payload;
    });
    builder.addCase(getTotalUnreadNotifications.rejected, (state, actions) => {
      state.totalUnreadNotifications = 0;
    });
  },
});

export const { setTabAllNotifications } = notificationsSlice.actions;
export default notificationsSlice.reducer;
