import useRefresh from 'hooks/useRefresh';
import type { FC } from 'react';
import { createContext, useEffect, useState } from 'react';
import { getAllPermissions } from 'services/configurations/security/rolesAndPermissions';
import {
  getCompanyDetailsByUser,
  getUserDetails,
  registerCompanyAccount,
  signInWithEmailAndPassword,
  verifyEmailTwoFactor,
  verifyGoogleTwoFactor,
} from 'services/shared/auth';
import { resetTenderWizard } from 'slices/tenderWizard';
import { useTypedDispatch } from 'store';
import type { CompanyDetails } from 'types/companySettings/organisation';
import type { UserPermission } from 'types/configurations/security';
import type { UserInfo } from 'types/user';
import LocalStorage from 'utils/LocalStorage';

export interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: UserInfo | null;
  company: CompanyDetails | null;
  permissions: UserPermission[] | null;
}

const initialAuthState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  company: null,
  permissions: null,
};

type Login = typeof signInWithEmailAndPassword;
type Register = typeof registerCompanyAccount;
type VerifyEmail = typeof verifyEmailTwoFactor;
type VerifyGoogle = typeof verifyGoogleTwoFactor;

export interface AuthContextValue extends State {
  login: Login;
  register: Register;
  verifyEmail: VerifyEmail;
  verifyGoogleCode: VerifyGoogle;
  logout: () => void;
  onRefreshAuth: () => void;
}

const AuthContext = createContext<AuthContextValue | null>(null);

if (process.env.NODE_ENV === 'development') {
  AuthContext.displayName = 'AuthContext';
}

const AuthProvider: FC = (props) => {
  const { children } = props;
  const [state, setState] = useState<State>(initialAuthState);
  const [refresh, onRefreshAuth] = useRefresh();
  const dispatch = useTypedDispatch();

  const login = async (params: Parameters<Login>[0]) => {
    const { username, password, rememberLogin } = params;
    const response = await signInWithEmailAndPassword({
      username,
      password,
      rememberLogin,
    });

    if (response.success && response.accessToken) {
      const { accessToken, refreshToken, isChangePasswordDefault } = response;
      LocalStorage.set('accessToken', accessToken);
      LocalStorage.set('refreshToken', refreshToken);
      LocalStorage.set('storedLoginInfo', {
        isChangePasswordDefault: isChangePasswordDefault,
      });

      onRefreshAuth();
    }

    return response;
  };

  const register = async (params: Parameters<Register>[0]) => {
    return registerCompanyAccount(params);
  };

  const verifyEmail = async (params: Parameters<VerifyEmail>[0]) => {
    const response = await verifyEmailTwoFactor(params);

    if (response.success && response.accessToken) {
      const { accessToken } = response;
      LocalStorage.set('accessToken', accessToken, onRefreshAuth);
    }

    return response;
  };

  const verifyGoogleCode = async (params: Parameters<VerifyGoogle>[0]) => {
    const response = await verifyGoogleTwoFactor(params);

    if (response.success && response.accessToken) {
      const { accessToken } = response;
      LocalStorage.set('accessToken', accessToken, onRefreshAuth);
    }

    return response;
  };

  const logout = () => {
    LocalStorage.remove('accessToken');
    LocalStorage.remove('refreshToken');
    LocalStorage.remove('storedLoginInfo');

    dispatch(resetTenderWizard());
    onRefreshAuth();
    window.location.reload();
  };

  useEffect(() => {
    const onAuthStateChanged = async () => {
      try {
        const accessToken = LocalStorage.get('accessToken');
        if (accessToken) {
          const [{ data: permissions }, { data: company }, { data: user }] =
            await Promise.all([
              getAllPermissions(),
              getCompanyDetailsByUser(),
              getUserDetails(),
            ]);

          if (user && company && permissions) {
            setState({
              isInitialized: true,
              isAuthenticated: true,
              user,
              company,
              permissions,
            });
          } else {
            setState({
              isInitialized: true,
              isAuthenticated: false,
              user: null,
              company: null,
              permissions: null,
            });
          }
        } else {
          setState({
            isInitialized: true,
            isAuthenticated: false,
            user: null,
            company: null,
            permissions: null,
          });
        }
      } catch (error) {
        console.log(error);
        setState({
          isInitialized: true,
          isAuthenticated: false,
          user: null,
          company: null,
          permissions: null,
        });
      }
    };

    onAuthStateChanged();
  }, [refresh]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        register,
        logout,
        verifyEmail,
        verifyGoogleCode,
        onRefreshAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;
export { AuthContext as default, AuthProvider, AuthConsumer };
