import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useAuth from 'hooks/useAuth';
import useForceUpdate from 'hooks/useForceUpdate';
import useMounted from 'hooks/useMounted';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  acceptTerm,
  checkLastAcceptTerm,
  LastAcceptTerm,
} from 'services/shared/document';
import DateFns from 'utils/DateFns';
import SignalrContext from './SignalrContext';
interface TermTranslateProps {
  currentTab: number;
  lastAcceptTermList: LastAcceptTerm[];
}

const TermTranslate = (props: TermTranslateProps) => {
  const { currentTab, lastAcceptTermList } = props;

  const findIndexByLanguageId = () => {
    const index = lastAcceptTermList[0].termTranslations.findIndex(
      (termTranslate) => termTranslate.languageId === currentTab
    );
    if (index < 0) {
      return 0;
    }

    return index;
  };

  return (
    <DialogContent>
      <Box
        dangerouslySetInnerHTML={{
          __html: `${
            lastAcceptTermList[0].termTranslations[findIndexByLanguageId()]
              .content
          }`,
        }}
      />
    </DialogContent>
  );
};

const TermContext: FC = ({ children }) => {
  const { logout, user } = useAuth();
  const { t } = useTranslation();

  const mounted = useMounted();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastAcceptTermList, setLastAcceptTermList] = useState<
    LastAcceptTerm[]
  >([]);
  const [rerender, forceUpdate] = useForceUpdate();

  const { rerenderPublishTerm } = useContext(SignalrContext);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    if (mounted.current) {
      setOpenDialog(false);
    }
  };

  const handleAcceptTerm = async () => {
    try {
      setLoading(true);
      const res = await acceptTerm(lastAcceptTermList[0].id);
      if (res.success) {
        setOpenDialog(false);
        forceUpdate();
      } else if (res.message === 'User already accepted') {
        //fix tam, doi thong tra messageCode
        setOpenDialog(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  };

  const handleDenyTerm = async () => {
    handleCloseDialog();
    logout();
  };

  useEffect(() => {
    // Check term here
    if (!user) return;

    (async () => {
      const res = await checkLastAcceptTerm();

      if (res.data.isAcceptedAll) {
        setOpenDialog(false);
      } else {
        setLastAcceptTermList(res.data.unacceptedTerms);
        handleOpenDialog();
      }
    })();
  }, [rerenderPublishTerm, rerender, user]);

  const getTermTranslate = (term: LastAcceptTerm | null) => {
    if (!user || !term) return;

    const termTranslate = term.termTranslations.find(
      (termTranslation) => termTranslation.languageId === user.languageId
    );

    return termTranslate;
  };

  const termTranslate = getTermTranslate(lastAcceptTermList[0]);

  if (!user) return null;

  return (
    <Fragment>
      {children}
      {lastAcceptTermList.length > 0 && (
        <Dialog
          open={openDialog}
          scroll="body"
          maxWidth="md"
          fullWidth
          disableEscapeKeyDown
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: 3,
            }}
          >
            <DescriptionOutlinedIcon
              color="disabled"
              sx={{ fontSize: 90, mb: 3 }}
            />
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 'bold', color: 'text.secondary' }}
            >
              {termTranslate?.title}
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              {t('documents.termsAndConditions.title.header', {
                version: termTranslate?.version,
                createDate: DateFns.format(
                  new Date(lastAcceptTermList[0].createdDate),
                  'dd.MM.yyy'
                ),
              })}
            </Typography>
          </Box>
          <Box
            sx={{
              minHeight: '50vh',
              height: 'calc(100vh - 237px - 50px - 62px - 80px)',
              overflowY: 'scroll',
            }}
          >
            <Divider />
            <TermTranslate
              currentTab={user.languageId}
              lastAcceptTermList={lastAcceptTermList}
            />
          </Box>
          <DialogActions sx={{ p: 2 }}>
            <Button
              startIcon={<CloseIcon />}
              variant="outlined"
              onClick={handleDenyTerm}
            >
              {t('common.action.cancel')}
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<CheckIcon />}
              onClick={handleAcceptTerm}
            >
              {t('crm.terms.action.accept')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default TermContext;
