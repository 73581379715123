import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import differenceInDays from 'date-fns/differenceInDays';

class Timmer {
  private MONTHS_PER_YEAR = 12;
  private NUM_OF_YEARS = 3;
  private MONTH_NAMES = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  private START_YEAR = 2020;
  private MILLIS_PER_DAY = 24 * 60 * 60 * 1000;

  getMonthCells() {
    const cells = [];
    for (let i = 0; i < this.MONTHS_PER_YEAR * this.NUM_OF_YEARS; i += 1) {
      const startMonth = i;
      const start = startOfMonth(new Date(this.START_YEAR, startMonth));
      const end = endOfMonth(new Date(this.START_YEAR, startMonth));

      cells.push({
        id: `m${startMonth}`,
        title: this.MONTH_NAMES[i % 12],
        start,
        end,
      });
    }

    return cells;
  }

  getDays() {
    const start = new Date(`${this.START_YEAR}`);
    const end = new Date(`${this.START_YEAR + this.NUM_OF_YEARS}`);

    const days = differenceInDays(end, start);

    return days;
  }
}

export default new Timmer();
