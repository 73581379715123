import Paper from '@mui/material/Paper';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import PageWrapper from 'components/PageWrapper';
import TimeLines from './TimeLines';

const Index = () => {
  return (
    <PageWrapper title={'Experiment'}>
      <PageBreadcrumbs
        category={'Experiment'}
        title={'Gantt'}
        breadcrumbs={[]}
      />
      <Paper>
        <TimeLines />
      </Paper>
    </PageWrapper>
  );
};

export default Index;
