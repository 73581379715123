import { styled } from '@mui/material/styles';

interface Props {
  style: object;
  src: string;
}

const Logo = (props: Props) => {
  return <LogoImg sx={props.style} src={props.src} alt="logo" />;
};

const LogoImg = styled('img')({});

export default Logo;
