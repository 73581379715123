import ChangePasswordContext from 'contexts/ChangePassworContext';
import RatingProvider from 'contexts/RatingContext';
import TermContext from 'contexts/TermContext';
import useAuth from 'hooks/useAuth';
import type { FC } from 'react';
import { Navigate } from 'react-router-dom';
import CookieConsent from './CookieConsent';

const PrivateRoute: FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <ChangePasswordContext>
      <RatingProvider>
        <TermContext>
          {children}
          {/* <CookieConsent /> */}
        </TermContext>
      </RatingProvider>
    </ChangePasswordContext>
  );
};

export default PrivateRoute;
