import type { ThemeOptions } from '@mui/material';
import type { Transpora } from '.';

// Transpora palette
const transpora: Transpora = {
  pagination: '#fde428',
  background: {
    disableField: '#e9ecef',
  },
  text: {
    white: '#FFFFFF',
  },
};

const palette: ThemeOptions = {
  palette: {
    mode: 'light',
    transpora,
    background: {
      default: '#f4f5f7',
      paper: '#FFFFFF',
    },
    primary: {
      light: '#5BE584',
      main: '#2FAC66',
      dark: '#007B55',
      contrastText: '#fff',
    },
  },
};

export default palette;
