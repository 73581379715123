import { yupResolver } from '@hookform/resolvers/yup';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ControllerTextField from 'components/Form/ControllerTextField';
import Form from 'components/Form/Form';
import LinkButton from 'components/LinkButton';
import Logo from 'components/Logo';
import Page from 'components/Page';
import { logoImages } from 'constants/placeholderImages';
import useMounted from 'hooks/useMounted';
import useNotification from 'hooks/useNotification';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { forgotPassword } from 'services/shared/auth';
import Regexs from 'utils/Regexs';
import sleep from 'utils/sleep';
import TypedObject from 'utils/TypedObject';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .trim('schema.trim')
    .strict(true)
    .required('schema.required')
    .matches(Regexs.email, 'schema.validEmail')
    .default(''),
});

interface FormData {
  username: string;
}

const ForgotPassword = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const setNotification = useNotification();
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: validationSchema.getDefault(),
  });

  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      const response = await forgotPassword(data.username);
      if (response.success) {
        setStep(2);
        setEmail(data.username);
      } else {
        // Fake call api in case: email not exit
        await sleep(2000);
      }

      setNotification({
        message: t('forgotPassword.notice'),
        severity: 'info',
      });
    } catch (error) {
      setNotification({
        error: t('notify.somethingWentWrong'),
      });
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Paper sx={{ p: 3, width: 1 }}>
            <Logo
              src={logoImages.image}
              style={{ maxWidth: '80%', marginLeft: '-12px' }}
            />
            <Divider sx={{ my: 3 }} />
            <Typography variant="body1">
              {t('forgotPassword.description')}
            </Typography>
            <Box sx={{ flexGrow: 1, mt: 3 }}>
              <ControllerTextField
                name="username"
                control={control}
                placeholder={t('form.emailAddress')}
                autoFocus
              />
            </Box>
            <Divider sx={{ my: 3 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <LinkButton to="/login">
                {t('forgotPassword.action.back')}
              </LinkButton>
              <LoadingButton
                loading={loading}
                startIcon={<MailOutlineIcon />}
                loadingPosition="start"
                type="submit"
                disabled={!TypedObject.isEmpty(errors)}
              >
                {t('forgotPassword.action.reset')}
              </LoadingButton>
            </Box>
          </Paper>
        );
      case 2:
        return (
          <Paper sx={{ p: 3, width: 1 }}>
            <Logo
              src={logoImages.image}
              style={{ maxWidth: '80%', marginLeft: '-12px' }}
            />
            <Divider sx={{ my: 3 }} />
            <Typography variant="body1">
              {t('forgotPassword.requestSent')}{' '}
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {email}
              </Box>
              . {t('forgotPassword.checkEmail')}
            </Typography>
            <Box sx={{ flexGrow: 1, mt: 3 }}>
              <ControllerTextField name="username" control={control} disabled />
            </Box>
            <Divider sx={{ my: 3 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <LinkButton to="/login">
                {t('forgotPassword.action.back')}
              </LinkButton>
            </Box>
          </Paper>
        );
    }
  };

  return (
    <Page title={t('forgotPassword.title')}>
      <Container
        maxWidth="sm"
        sx={{
          display: 'grid',
          placeContent: 'center',
          position: 'absolute',
          flexGrow: 1,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          {renderStep()}
        </Form>
      </Container>
    </Page>
  );
};

export default ForgotPassword;
