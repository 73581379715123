import { LanguageCode } from './../../types/common';
import type {
  CommonParams,
  CommonResponse,
  Country,
  CountryDetails,
  CountryLanguage,
  Languages,
  PhoneCode,
  Response,
  Salutation,
  SearchParams,
} from 'types/common';
import type { LoadStatus } from 'types/companySettings/loads';
import type {
  CompanyLocationDetails,
  MarketSegment,
} from 'types/companySettings/organisation';
import type { StatisticCategory } from 'types/dashboard';
import type { SearchAddress } from 'types/tenderwizard';
import HttpClient from 'utils/HttpClient';

// Get list name country
const getListNameCountry = async (params: Pick<CommonParams, 'searchText'>) => {
  return HttpClient.post<typeof params, Country[]>(
    '/shared/Country/GetListNameCountry',
    params
  );
};

// Get code phone of country
interface GetCodePhoneOfCountryResponse {
  phoneNumberCountryCode: string;
}
const getCodePhoneOfCountry = async (countryId: number) => {
  return HttpClient.get<number, GetCodePhoneOfCountryResponse>(
    '/shared/Country/GetCodePhoneOfCountry',
    { params: { countryId } }
  );
};

// Get location headquater
const getLocationHeadQuater = async (companyId: number) => {
  return HttpClient.get<number, CommonResponse<CompanyLocationDetails>>(
    '/shared/Company/GetLocationHeadQuarterByCompanyId',
    { params: { companyId } }
  );
};

const getNumberLocationInCountryByCompanyId = async (params: {
  companyId: number;
  countryId: number;
}) => {
  return HttpClient.get<typeof params, number>(
    '/shared/Location/NumberLocationInCountryByCompanyId',
    { params }
  );
};

const getListNameLanguage = async (
  params: Pick<CommonParams, 'searchText'>
) => {
  return HttpClient.post<typeof params, Languages>(
    '/shared/Country/GetListNameLanguage',
    params
  );
};

// Get list name market segments
const getListNameMarketSegments = async (
  params: Pick<CommonParams, 'searchText' | 'pageIndex' | 'pageSize'>
) => {
  return HttpClient.post<typeof params, MarketSegment[]>(
    '/shared/MasterData/ListNameMarketSegments',
    params
  );
};

export interface FileUrl {
  name: string;
  url: string;
}

export interface CountryInfo {
  id: number;
  currencyCode: string;
  phoneCode: string;
  languageDefault: {
    languageId: number;
    languageName: string;
  };
  standardFees: number;
  flagImage: string;
  vat: number;
}

const getCountryInfoById = async (countryId: number) => {
  return HttpClient.get<number, Response<CountryInfo>>(
    '/shared/Country/GetCountryInfoById',
    { params: { countryId } }
  );
};

// Get country details
const getCountryDetails = async (countryId: number) => {
  return HttpClient.get<number, CommonResponse<CountryDetails>>(
    '/shared/Country/GetCountryInfoById',
    { params: { countryId } }
  );
};

export interface CompanyBidInfo {
  companyId: number;
  companyName: string;
  cityName: string;
  cityCode: string;
  streetNumber: string;
  streetName: string;
}

const getCompanyAddressById = async (companyId: number) => {
  return HttpClient.get<number, Response<CompanyBidInfo>>(
    `/shared/Company/GetCompanyAddressById?companyId=${companyId}`
  );
};

// Get salutation of language
const getSalutationOfLanguage = async (languageId: number) => {
  return HttpClient.get<number, CommonResponse<Salutation[]>>(
    '/shared/MasterData/ListSalutationByLanguageId',
    { params: { languageId } }
  );
};

// Get languages by country
const getLanguagesByCountry = async (countryId: number) => {
  return HttpClient.get<number, CommonResponse<CountryLanguage[]>>(
    '/shared/MasterData/ListLanguageByCountryId',
    { params: { countryId } }
  );
};

interface VerifyRegistrationParams {
  id: number;
  token: string;
}
const verifyRegistration = async (params: VerifyRegistrationParams) => {
  const { id, token } = params;
  return HttpClient.get<VerifyRegistrationParams, CommonResponse>(
    '/shared/Company/VerifyRegistration',
    { params: { id, token } }
  );
};

const updateUserLanguage = async (languageCode: string) => {
  return HttpClient.put<string, CommonResponse>(
    `/shared/User/UpdateUserLanguage?languageCode=${languageCode}`
  );
};

const getListPhoneNumberCountryCode = async (
  params: Pick<SearchParams, 'searchText'>
) => {
  return HttpClient.post<typeof params, CommonResponse<PhoneCode[]>>(
    '/shared/Country/ListPhoneNumberCountryCode',
    params
  );
};

const getDefaultCompanyAddress = async () => {
  return HttpClient.get<null, CommonResponse<SearchAddress>>(
    '/company/setting/Address/GetAddressDefaultByCompany'
  );
};

// Get statistic categories
const getStatisticCategories = async () => {
  return HttpClient.get<null, StatisticCategory[]>(
    '/shared/Company/GetStatisticCategory'
  );
};

// Get countries company
const getCountriesByCompany = async (id: number) => {
  return HttpClient.get<number, CommonResponse<Country[]>>(
    '/shared/Company/ListCountryByCompanyId',
    { params: { id } }
  );
};

// Get countries by country administrator
const getCountriesByCountryAdministrator = async (id: number) => {
  return HttpClient.get<number, CommonResponse<Country[]>>(
    '/shared/User/ListCountryByCountryAdminId',
    { params: { id } }
  );
};

// Get load statuses
const getLoadStatuses = async () => {
  return HttpClient.get<null, CommonResponse<LoadStatus[]>>(
    '/shared/MasterData/ListLoadStatus'
  );
};

export interface Standards {
  standerValueNameTranslation: {
    languageId: number;
    translation: string;
  }[];
  value?: number;
  unit: string;
}

const getStandards = async (productFamilyId: number) => {
  return HttpClient.get<number, CommonResponse<Standards[]>>(
    '/shared/MasterData/ListStanderValuesByCategoryId',
    { params: { productFamilyId } }
  );
};

export interface NameTranslations {
  languageCode: LanguageCode;
  translation: string;
}

export interface ListAccessory {
  name: string;
  nameTranslationJson: string;
  nameTranslations: NameTranslations[];
  id: number;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  isDelete: boolean;
  isActive: true;
}

const getListAccessory = async () => {
  return HttpClient.get<null, CommonResponse<ListAccessory[]>>(
    '/shared/MasterData/ListAccessory'
  );
};

export interface TempAccessoryTypeByAccessoryId {
  name: string;
  nameTranslationJson: string;
  nameTranslations: NameTranslations[];
  id: number;
  unit: string;
}

const getListTempAccessoryTypeByAccessoryId = async (AccessoryId: number) => {
  return HttpClient.get<
    number,
    CommonResponse<TempAccessoryTypeByAccessoryId[]>
  >('shared/MasterData/ListTempAccessoryTypeByAccessoryId', {
    params: { AccessoryId },
  });
};

interface GetStateByPostCodeParam {
  countryCode: string;
  postCode: string;
}

export interface ListStateByPostCode {
  adminCode1: string;
  adminCode2: string;
  adminCode3: string;
  adminName1: string;
  adminName2: null;
  adminName3: string;
  countryCode: string;
  id: number;
  latitude: number;
  longitude: number;
  nameDE: string;
  nameEN: string;
  nameES: string;
  nameFR: string;
  nameIT: string;
  placeName: string;
  postCode: string;
}

const getStateByPostCode = async (params: GetStateByPostCodeParam) => {
  const { countryCode, postCode } = params;
  return HttpClient.get<
    GetStateByPostCodeParam,
    CommonResponse<ListStateByPostCode[]>
  >('/shared/Country/GetStateByPostCode', {
    params: { postCode, countryCode },
  });
};

const uploadCompanyLogo = async (params: FormData) => {
  return HttpClient.post<FormData, CommonResponse<FileUrl>>(
    '/shared/Upload/UploadCompnayLogo',
    params
  );
};

const uploadAvatarContact = async (params: FormData) => {
  return HttpClient.post<FormData, CommonResponse<FileUrl>>(
    '/shared/Upload/UploadAvatarContact',
    params
  );
};

const uploadAvatarUser = async (params: FormData) => {
  return HttpClient.post<FormData, CommonResponse<FileUrl>>(
    '/shared/Upload/UploadAvatarUser',
    params
  );
};

const uploadLoadImage = async (params: FormData) => {
  return HttpClient.post<FormData, CommonResponse<FileUrl[]>>(
    '/shared/Upload/UploadLoadImage',
    params,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

const uploadDocuments = async (params: FormData) => {
  return HttpClient.post<FormData, CommonResponse<FileUrl[]>>(
    '/shared/Upload/UploadDocuments',
    params,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

const uploadDocumentTenderLoad = async (params: FormData) => {
  return HttpClient.post<FormData, CommonResponse<FileUrl[]>>(
    '/shared/Upload/UploadDocumentTenderLoad',
    params,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

export {
  getListNameCountry,
  getListNameLanguage,
  getListNameMarketSegments,
  getCodePhoneOfCountry,
  getCountryInfoById,
  getCompanyAddressById,
  getSalutationOfLanguage,
  getLanguagesByCountry,
  verifyRegistration,
  updateUserLanguage,
  getCountryDetails,
  getListPhoneNumberCountryCode,
  getStatisticCategories,
  getCountriesByCompany,
  getCountriesByCountryAdministrator,
  getLoadStatuses,
  getLocationHeadQuater,
  getNumberLocationInCountryByCompanyId,
  getDefaultCompanyAddress,
  getStandards,
  getListAccessory,
  getListTempAccessoryTypeByAccessoryId,
  getStateByPostCode,
  uploadCompanyLogo,
  uploadAvatarContact,
  uploadAvatarUser,
  uploadLoadImage,
  uploadDocuments,
  uploadDocumentTenderLoad,
};
