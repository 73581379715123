import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Scrollbar from 'components/Scrollbar';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import Cluster from './Cluster';
import timmer from './utils/timmer';

interface TimeLine {
  from: Date;
  to: Date;
}

interface Serie {
  name: string;
  timelines: TimeLine[];
}

const series: Serie[] = [
  {
    name: 'Construction machine 1',
    timelines: [
      {
        from: new Date('2020-1-1'),
        to: new Date('2020-5-10'),
      },
      {
        from: new Date('2020-7-1'),
        to: new Date('2021-5-5'),
      },
    ],
  },
  {
    name: 'Construction machine 2',
    timelines: [
      {
        from: new Date('2020-3-3'),
        to: new Date('2020-4-10'),
      },
      {
        from: new Date('2020-5-1'),
        to: new Date('2020-7-5'),
      },
    ],
  },
];

const TimeLines = () => {
  const cells = timmer.getMonthCells();
  const days = timmer.getDays() * 2;

  return (
    <Scrollbar sx={{ height: 1 }}>
      <Box sx={{ width: `${days + 16}px`, p: 1 }}>
        <Box sx={{ display: 'flex', width: 1 }}>
          {cells.map((cell, i) => {
            const { title, start } = cell;
            const days = getDaysInMonth(start);
            return (
              <Box
                key={i}
                sx={{
                  flex: `0 0 ${days * 2}px`,
                  border: 1,
                  borderRight: 0,
                  borderColor: 'divider',
                  py: 0.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                  '&:last-child': {
                    borderRight: 1,
                    borderColor: 'divider',
                  },
                }}
              >
                <Typography variant="subtitle2">{title}</Typography>
              </Box>
            );
          })}
        </Box>
        {series.map((serie, i) => {
          const { timelines, name } = serie;
          return (
            <Box
              key={i}
              sx={{
                height: 50,
                position: 'relative',
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              {timelines.map((timeline, i) => (
                <Cluster key={i} name={name} timeline={timeline} />
              ))}
            </Box>
          );
        })}
      </Box>
    </Scrollbar>
  );
};

export default TimeLines;
