import type { CommonResponse } from 'types/common';
import type { CompanyDetails } from 'types/companySettings/organisation';
import { UserInfo } from 'types/user';
import HttpClient from 'utils/HttpClient';
import LocalStorage from 'utils/LocalStorage';

// Sign in with email and password
export interface SignInWithEmailAndPasswordParams {
  username: string;
  password: string;
  rememberLogin: boolean;
}
export interface SignInWithEmailAndPasswordResponse {
  accessToken: string | null;
  twoFactorId: number;
  httpStatusCode: number;
  isChangePasswordDefault: boolean;
  refreshToken: string;
  message: string;
  success: boolean;
  userId: null;
  messageCode: string | null;
}
const signInWithEmailAndPassword = async (
  params: SignInWithEmailAndPasswordParams
) => {
  return HttpClient.post<typeof params, SignInWithEmailAndPasswordResponse>(
    '/shared/Auth/Login',
    params
  );
};

// Refresh token
interface RefreshTokenResponse {
  accessToken: string;
  success: boolean;
  status: number;
}
const refreshToken = async () => {
  const refreshToken = LocalStorage.get('refreshToken');
  if (!refreshToken) {
    return null;
  }
  return HttpClient.post<{ refreshToken: string }, RefreshTokenResponse>(
    '/shared/Auth/RefreshToken',
    { refreshToken }
  );
};

// Refresh token
const logOut = async () => {
  const refreshToken = LocalStorage.get('refreshToken');
  if (!refreshToken) {
    return null;
  }
  return HttpClient.post<{ refreshToken: string }, CommonResponse>(
    '/shared/Auth/Logout',
    { refreshToken }
  );
};

export interface RegisterCompanyAccountParams {
  companyName: string;
  streetName: string;
  streetNumber: string | null;
  lastName: string;
  firstName: string;
  cityCode: string;
  cityName: string | null;
  email: string;
  countryId: number;
  languageId: number;
  companyRoleIds: number[];
  latitude: number | null;
  longitude: number | null;
}
export interface RegisterCompanyAccount {
  avatarAssociateContact: null;
  businessPhoneNumber: null;
  businessPhoneNumberCountryCode: null;
  cityCode: string;
  cityName: string;
  companyEmail: string;
  companyName: string;
  companyPhoneNumber: null;
  companyPhoneNumberCountryCode: null;
  companyRole: null;
  companyRoleId: number;
  companyStatusId: number;
  countryId: number;
  createdDate: string;
  email: string;
  firstName: string;
  id: number;
  image: null;
  jobTitle: null;
  languageId: number;
  lastName: string;
  marketSegmentIdList: [];
  marketSegmentIds: null;
  mobilePhoneNumber: null;
  mobilePhoneNumberCountryCode: null;
  salutation: null;
  streetName: string;
  streetNumber: string;
  uidNumber: null;
  updateBy: null;
  updatedDate: null;
  verifyToken: string;
  website: null;
  countryName: string;
}
const registerCompanyAccount = async (params: RegisterCompanyAccountParams) => {
  return HttpClient.post<typeof params, CommonResponse<RegisterCompanyAccount>>(
    '/shared/Company/Register',
    params
  );
};

export interface VerifyEmailTwoFactorParams {
  code: string;
  userName: string;
  rememberClient: boolean;
}
const verifyEmailTwoFactor = async (params: VerifyEmailTwoFactorParams) => {
  return HttpClient.post<typeof params, SignInWithEmailAndPasswordResponse>(
    '/shared/Auth/VerifyEmailTwoFactor',
    params
  );
};

// Get company details by user
const getCompanyDetailsByUser = async () => {
  return HttpClient.get<null, CommonResponse<CompanyDetails>>(
    '/shared/Company/GetCompanyByUser'
  );
};

const getUserDetails = async () => {
  return HttpClient.get<null, CommonResponse<UserInfo>>(
    '/shared/User/GetUserDetail'
  );
};

interface UserUpdateProfileParams {
  salutation: string | null;
  firstName: string;
  lastName: string;
  phoneNumberCountryCode: string;
  phoneNumber: string;
  mobileNumberCountryCode: string | null;
  mobileNumber: string | null;
  language: number | null;
  jobTitle: string | null;
  image: string | null;
}
const userUpdateProfile = async (params: UserUpdateProfileParams) => {
  return HttpClient.post<UserUpdateProfileParams, CommonResponse>(
    '/shared/User/UserUpdateProfile',
    params
  );
};

export interface TwoFa {
  id: number;
  authenticationName: 'Mail authentication' | 'Google Authenticator';
  status: boolean;
}
interface GetListTwoFaByUserResponse extends CommonResponse<TwoFa[]> {
  totalRows: number;
}
const getListTwoFaByUser = async () => {
  return HttpClient.get<null, GetListTwoFaByUserResponse>(
    '/shared/User/ListTwoFaByUser'
  );
};

interface UpdateTwoFaParams {
  twoFaId: number;
  status: boolean;
}

const updateTwoFaByUserId = async (params: UpdateTwoFaParams) => {
  return HttpClient.post<UpdateTwoFaParams, CommonResponse>(
    'shared/User/Update2FAByUserId',
    params
  );
};

interface GenerateEmailCode {
  email: string;
}

const generateEmailCode = async (params: GenerateEmailCode) => {
  return HttpClient.post<GenerateEmailCode, CommonResponse>(
    '/shared/Auth/GenerateCodeEmailTwoFactor',
    params
  );
};

interface ChangePassword {
  email: string;
  oldPassword: string;
  newPassword: string;
}

const changePassword = async (params: ChangePassword) => {
  return HttpClient.post<ChangePassword, CommonResponse>(
    '/shared/User/ChangePassword',
    params
  );
};

interface ChangePasswordDefault {
  email: string;
  newPassword: string;
}

const changePasswordDefault = async (params: ChangePasswordDefault) => {
  return HttpClient.post<ChangePasswordDefault, CommonResponse>(
    '/shared/User/ChangePasswordDefault',
    params
  );
};

// Forgot password
const forgotPassword = async (email: string) => {
  return HttpClient.get<string, CommonResponse>('/shared/Auth/Forgotpassword', {
    params: { email },
  });
};

interface ForgotPasswordConfirm {
  email: string;
  password: string;
  token: string;
}

const forgotPasswordConfirm = async (params: ForgotPasswordConfirm) => {
  return HttpClient.post<ForgotPasswordConfirm, CommonResponse>(
    '/shared/Auth/ForgotPasswordComfirm',
    params
  );
};

interface GenerateCodeGoogleTwoFactor {
  email: string;
}

export interface GenerateCodeGoogleTwoFactorResponse {
  account: string;
  manualEntryKey: string;
  qrCodeSetupImageUrl: string;
}

const generateCodeGoogleTwoFactor = async (
  params: GenerateCodeGoogleTwoFactor
) => {
  return HttpClient.post<
    GenerateCodeGoogleTwoFactor,
    GenerateCodeGoogleTwoFactorResponse
  >('/shared/Auth/GenerateCodeGoogleTwoFactor', params);
};

export interface VerifyGoogleTwoFactorParams {
  code: string;
  userName: string;
  rememberClient: boolean;
}

export interface VerifyGoogleTwoFactorResponse {
  accessToken: string | null;
  twoFactorId: number;
  httpStatusCode: number;
  message: string;
  success: boolean;
  userId: null;
}

const verifyGoogleTwoFactor = async (params: VerifyGoogleTwoFactorParams) => {
  return HttpClient.post<
    VerifyGoogleTwoFactorParams,
    VerifyGoogleTwoFactorResponse
  >('/shared/Auth/VerifyGoogleTwoFactor', params);
};

export {
  signInWithEmailAndPassword,
  getCompanyDetailsByUser,
  registerCompanyAccount,
  verifyEmailTwoFactor,
  getUserDetails,
  userUpdateProfile,
  getListTwoFaByUser,
  updateTwoFaByUserId,
  generateEmailCode,
  changePassword,
  forgotPassword,
  forgotPasswordConfirm,
  generateCodeGoogleTwoFactor,
  verifyGoogleTwoFactor,
  refreshToken,
  changePasswordDefault,
  logOut,
};
