import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import type { MouseEvent } from 'types/react';
import { Fragment, useEffect, useState } from 'react';
import Scrollbar from 'components/Scrollbar';
import {
  getNotificationByUserId,
  updateNotificationIsSent,
} from 'services/shared/Notification';
import type { FilterNotificationsParams } from 'services/shared/Notification';
import type { Notification } from 'services/shared/Notification';
import { useTranslation } from 'react-i18next';
import NotificationContent from './NotificationContent';
import ShowMoreTextWrap from 'components/ShowMoreTextWrap';
import { useTypedDispatch, useTypedSelector } from 'store';
import sleep from 'utils/sleep';
import { getTotalUnreadNotifications } from 'slices/notifications';

const WIDTH = 360;

const Notifications = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [listNotification, setListNotification] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const totalUnreadNotifications = useTypedSelector(
    (state) => state.notifications.totalUnreadNotifications
  );
  const dispatch = useTypedDispatch();
  const [filters] = useState<FilterNotificationsParams>({
    pageIndex: 1,
    pageSize: 5,
    status: null,
  });

  const open = Boolean(anchorEl);

  //count notifications unRead
  useEffect(() => {
    if (open) return;
    dispatch(getTotalUnreadNotifications(filters));
  }, [open, filters, dispatch]);

  // get notifications
  useEffect(() => {
    if (!open) return;
    setLoading(true);
    getNotificationByUserId(filters)
      .then((response) => {
        const { data, total } = response;
        if (data) {
          setTotal(total);
          setListNotification(data.notifies || []);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [open, filters]);

  useEffect(() => {
    if (!anchorEl) return;

    const listIdUnRead = listNotification
      .filter((notification) => !notification.isRead)
      .map((notification) => notification.id);

    if (Array.isArray(listIdUnRead) && listIdUnRead.length) {
      updateNotificationIsSent(listIdUnRead).catch((error) => {
        console.log(error);
      });
    }
  }, [listNotification, anchorEl]);

  const handleToggleNotifications: MouseEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotifications = async () => {
    setAnchorEl(null);
    await sleep(150);
    setListNotification([]);
  };

  return (
    <Fragment>
      <IconButton onClick={handleToggleNotifications}>
        <Badge badgeContent={totalUnreadNotifications} color="primary" max={9}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 47,
          horizontal: 'center',
        }}
        keepMounted
        onClose={handleCloseNotifications}
        open={open}
        PaperProps={{
          sx: {
            width: WIDTH,
            overflow: 'hidden',
          },
        }}
      >
        <List
          subheader={
            <Box
              sx={{
                px: 1,
                py: 1.5,
                bgcolor: 'primary.main',
              }}
            >
              <Typography variant="h6" sx={{ color: 'common.white' }}>
                {t('common.notifications')}
              </Typography>
            </Box>
          }
        >
          <Scrollbar
            sx={{
              maxHeight: 'calc(100vh - 120px)',
            }}
          >
            <NotificationContent
              total={total}
              loading={loading && open}
              onClose={handleCloseNotifications}
            >
              {listNotification.map((notification) => {
                const { id, notificationTitle, notificationContent } =
                  notification;
                return (
                  <ListItem
                    key={id}
                    alignItems="flex-start"
                    sx={{ px: 1, py: 0, my: 1 }}
                  >
                    <ListItemText>
                      <Typography
                        component="p"
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: notificationTitle,
                        }}
                      />
                      <ShowMoreTextWrap
                        lines={2}
                        width={WIDTH}
                        sx={{
                          color: 'text.secondary',
                          fontSize: 14,
                        }}
                      >
                        {notificationContent}
                      </ShowMoreTextWrap>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </NotificationContent>
          </Scrollbar>
        </List>
      </Popover>
    </Fragment>
  );
};

export default Notifications;
