import AuthContext from 'contexts/AuthContext';
import { useContext } from 'react';

const usePermissions = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('Forgot to wrap component in AuthContext');
  }

  const { permissions } = authContext;

  if (!permissions) {
    throw new Error('Unauthorized');
  }

  return permissions;
};

export default usePermissions;
