import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LinkButton from 'components/LinkButton';
import Page from 'components/Page';
import { useTranslation } from 'react-i18next';

const Common = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('errors.common.title')}>
      <Container
        sx={{
          display: 'grid',
          placeContent: 'center',
          flexGrow: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'error.main',
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t('errors.common.description')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 5 }}>
            {t('errors.common.subDescription')}
          </Typography>
          <LinkButton to="/" variant="contained" startIcon={<ArrowBackIcon />}>
            {t('errors.common.back')}
          </LinkButton>
        </Box>
      </Container>
    </Page>
  );
};

export default Common;
