import React, { SyntheticEvent } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Rating, TextareaAutosize, Typography } from '@mui/material';

interface Props {
  topic: string;
  question: string;
  number: number;
  starRating: number[];
  setStarRating: Function;
  recommend: string[];
  setRecommends: Function;
}

const RatingQuestion = (props: Props) => {
  const { t } = useTranslation();

  const onChangeStarRating = (_event: SyntheticEvent, value: number | null) => {
    if (!value) return;
    const newState = [...props.starRating];
    newState[props.number] = value;
    props.setStarRating(newState);
  };

  const onChangeRecommend = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (value.length > 300) return;
    const newState = [...props.recommend];
    newState[props.number] = value;
    props.setRecommends(newState);
  };

  return (
    <QuestionRatingContainer>
      <Typography component="h6" sx={{ fontWeight: 700 }}>
        {props.topic}
      </Typography>
      <Typography component="p">{props.question}</Typography>
      <Ratings
        name="simple-controlled"
        size="large"
        value={props.starRating[props.number]}
        onChange={onChangeStarRating}
      />
      {props.starRating[props.number] < 3 &&
      props.starRating[props.number] !== 0 ? (
        <>
          <TextareaAutosize
            aria-label="recommend"
            placeholder={t('rating.placeholder')}
            minRows={5}
            maxRows={5}
            style={{ width: '100%', padding: 8 }}
            value={props.recommend[props.number]}
            onChange={onChangeRecommend}
          />
          <Typography sx={{ fontSize: 12, textAlign: 'end' }}>
            {props.recommend[props.number].length}/300
          </Typography>
        </>
      ) : null}
    </QuestionRatingContainer>
  );
};

const Ratings = styled(Rating)({
  '.MuiRating-icon': {
    color: '#FAAF00',
  },
});

const QuestionRatingContainer = styled('div')({
  marginBottom: 16,
});

export default RatingQuestion;
