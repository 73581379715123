import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { version } from 'config';

const Footer = () => {
  return (
    <Box>
      <Divider />
      <FooterRoot>
        <Box>
          <Typography variant="caption" sx={{ fontWeight: 500 }}>
            TMS Version {version}.
          </Typography>{' '}
          <Typography variant="caption">
            Copyright ©{' '}
            <Link href="https://www.disporeon.com">www.disporeon.com</Link>
          </Typography>
        </Box>
        <Typography variant="caption">
          Made with <StyledSpan>&#10084;</StyledSpan> in Switzerland.
        </Typography>
      </FooterRoot>
    </Box>
  );
};

const FooterRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const StyledSpan = styled('span')({
  color: 'red',
});

export default Footer;
