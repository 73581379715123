import useUser from 'hooks/useUser';
import { Navigate } from 'react-router-dom';

const SplashRoute = () => {
  const {
    user: { isInternal },
  } = useUser();

  const path = isInternal ? '/dashboard' : '/dashboard';

  return <Navigate to={path} />;
};

export default SplashRoute;
