import HttpClient from 'utils/HttpClient';
import { CommonResponse } from 'types/common';
import {
  ICompanyCarrierResponse,
  IShipperRateCarrierParams,
  IUserRateTransporaParams,
} from '../utils/types';

export const apiCheckConditionUserRateTransopra = () => {
  return HttpClient.get<any, CommonResponse<boolean>>(
    '/company/dashboard/Rate/CheckConditionRateTranspora'
  );
};

export const apiCheckShipperRateCarriersByUser = () => {
  return HttpClient.post<any, CommonResponse<ICompanyCarrierResponse[]>>(
    '/company/dashboard/Rate/ListCompanyCarrierRateByUser',
    {
      searchText: '',
      pageIndex: 1,
      pageSize: 20,
    }
  );
};

export const apiPostUserRateTranspora = (
  params: IUserRateTransporaParams | Pick<IUserRateTransporaParams, 'isSkip'>
) => {
  return HttpClient.post<typeof params, CommonResponse>(
    '/company/dashboard/Rate/UserRateTranspora',
    params
  );
};

export const apiPostShipperRateCarrier = (
  params: IShipperRateCarrierParams | Pick<IShipperRateCarrierParams, 'isSkip'>
) => {
  return HttpClient.post<typeof params, CommonResponse>(
    '/company/dashboard/Rate/ShipperRateCarrier',
    params
  );
};
