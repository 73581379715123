import { styled } from '@mui/material/styles';
import type { FC } from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

const DashboardLayout: FC = () => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleToggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <LayoutRoot collapsed={collapsed}>
      <Header
        collapsed={collapsed}
        onToggleSidebar={handleToggleSidebar}
        onToggleCollapsed={handleToggleCollapsed}
      />
      <Content>
        <Outlet />
      </Content>
      <Sidebar
        collapsed={collapsed}
        openSidebar={openSidebar}
        onCloseSidebar={handleCloseSidebar}
      />
      <Footer />
    </LayoutRoot>
  );
};

const LayoutRoot = styled('div', {
  shouldForwardProp: (prop: string) => !['collapsed'].includes(prop),
})<{ collapsed: boolean }>(({ theme, collapsed }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: collapsed ? 75 : 280,
  },
}));

const Content = styled('main')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
});

export default DashboardLayout;
