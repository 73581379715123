import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  company: {
    id: 0,
    companyName: '',
    email: '',
    countryId: 0,
    phoneNumberCountryCode: '',
    phoneNumber: '',
    companyStreetName: '',
    companyStreetNumber: '',
    cityName: '',
    cityCode: '',
    companyStatusId: 0,
    uidNumber: '',
    image: '',
    imageType: '',
    divisionId: 0,
    website: '',
  },
  brandId: 0,
  brandName: '',
  productFamilyId: 0,
  productFamilyName: '',
  companyId: 0,
  vehicleTypeId: 0,
  vehicleTypeName: '',
  freightCategoryId: 0,
  freightCategoryName: '',
  length: 0,
  width: 0,
  height: 0,
  weight: 0,
  isActive: true,
  imageUrl: [],
  serialNo: '',
  inventoryNo: '',
  type: '',
  plate: '',
};

const vehicleLoadSlice = createSlice({
  name: '@VehicleLoadSlice',
  initialState,
  reducers: {
    addLoad(_state, action) {
      return action.payload;
    },
  },
});

export const { addLoad } = vehicleLoadSlice.actions;
export default vehicleLoadSlice.reducer;
