import PrivateRoleRoute from 'components/PrivateRoleRoute';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Loadable from './Loadable';

// Load and Categories
const Brands = Loadable(lazy(() => import('views/Configurations/LoadsCategories/Brands')));
const BrandDetails = Loadable(lazy(() => import('views/Configurations/LoadsCategories/Brands/BrandDetails')));
const CreateBrand = Loadable(lazy(() => import('views/Configurations/LoadsCategories/Brands/CreateBrand')));
const EditBrand = Loadable(lazy(() => import('views/Configurations/LoadsCategories/Brands/EditBrand')));

// Employeess
const Employees = Loadable(lazy(() => import('views/Configurations/Organisation/Employees')));
const EditEmployee = Loadable(lazy(() => import('views/Configurations/Organisation/Employees/EditEmployee')));
const CreateEmployee = Loadable(lazy(() => import('views/Configurations/Organisation/Employees/CreateEmployee')));
const EmployeeDetails = Loadable(lazy(() => import('views/Configurations/Organisation/Employees/EmployeeDetails')));
const ConstructionMachineLogs = Loadable(lazy(() => import('views/Configurations/Logs/ConstructionMachineLogs')));

// Company
const Company = Loadable(lazy(() => import('views/Configurations/Organisation/Company')));

// Roles And Permissions
const RolesAndPermissions = Loadable(lazy(() => import('views/Configurations/Security/RolesAndPermissions')));
const RolesPermissionsDetails = Loadable(
  lazy(() => import('views/Configurations/Security/RolesAndPermissions/RolesAndPermissionsDetails'))
);

// Authentication
const Authentication = Loadable(lazy(() => import('views/Configurations/Security/Authentication')));

// Maintenance Windows
const Maintenance = Loadable(lazy(() => import('views/Configurations/Security/Maintenance')));
const MaintenanceDetails = Loadable(lazy(() => import('views/Configurations/Security/Maintenance/MaintenanceDetails')));
const MaintenanceEdit = Loadable(lazy(() => import('views/Configurations/Security/Maintenance/EditMaintenance')));
const MaintenanceCreate = Loadable(lazy(() => import('views/Configurations/Security/Maintenance/CreateMaintenance')));

// Log Files
const LogFiles = Loadable(lazy(() => import('views/Configurations/Security/Logfiles')));

// Product families
const ProductFamilies = Loadable(lazy(() => import('views/Configurations/LoadsCategories/ProductFamilies')));
const CreateProductFamily = Loadable(
  lazy(() => import('views/Configurations/LoadsCategories/ProductFamilies/CreateProductFamily'))
);
const EditProductFamily = Loadable(
  lazy(() => import('views/Configurations/LoadsCategories/ProductFamilies/EditProductFamily'))
);
const ProductFamilyDetails = Loadable(
  lazy(() => import('views/Configurations/LoadsCategories/ProductFamilies/ProductFamilyDetails'))
);

// Markets => Countries
const Countries = Loadable(lazy(() => import('views/Configurations/Markets/Countries')));
const CountryDetails = Loadable(lazy(() => import('views/Configurations/Markets/Countries/CountryDetails')));

// Notifications
const Notifications = Loadable(lazy(() => import('views/Configurations/Notifications')));
const EditNotification = Loadable(lazy(() => import('views/Configurations/Notifications/EditNotification')));

const ConfigurationADMsRoutes: RouteObject = {
  path: 'configurations',
  element: <Outlet />,
  children: [
    {
      path: 'organisation',
      element: <Outlet />,
      children: [
        {
          path: 'employees',
          element: (
            <PrivateRoleRoute companyRoles={['internal']} path="internal.employee">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <Employees /> },
            { path: 'create', element: <CreateEmployee /> },
            { path: ':employeeId/edit', element: <EditEmployee /> },
            { path: ':employeeId', element: <EmployeeDetails /> },
          ],
        },
        {
          path: 'company',
          element: (
            <PrivateRoleRoute companyRoles={['internal']} path="internal.company">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [{ index: true, element: <Company /> }],
        },
      ],
    },
    {
      path: 'markets',
      element: <Outlet />,
      children: [
        {
          path: 'countries',
          element: (
            <PrivateRoleRoute companyRoles={['internal']} path="*">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <Countries /> },
            { path: ':countryId', element: <CountryDetails /> },
            { path: ':countryId/edit', element: <CountryDetails /> },
          ],
        },
      ],
    },
    {
      path: 'loads-categories',
      element: <Outlet />,
      children: [
        {
          path: 'brands',
          element: (
            <PrivateRoleRoute companyRoles={['internal']} path="brand">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <Brands /> },
            { path: ':brandId', element: <BrandDetails /> },
            { path: 'create', element: <CreateBrand /> },
            { path: ':brandId/edit', element: <EditBrand /> },
          ],
        },
        {
          path: 'product-families',
          element: (
            <PrivateRoleRoute companyRoles={['internal']} path="productFamily">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <ProductFamilies /> },
            { path: ':productFamilyId', element: <ProductFamilyDetails /> },
            { path: 'create', element: <CreateProductFamily /> },
            { path: ':productFamilyId/edit', element: <EditProductFamily /> },
          ],
        },
      ],
    },
    {
      path: 'security',
      element: <Outlet />,
      children: [
        {
          path: 'roles-permissions',
          element: (
            <PrivateRoleRoute companyRoles={['internal']} path="*">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <RolesAndPermissions /> },
            { path: ':roleId', element: <RolesPermissionsDetails /> },
          ],
        },
        {
          path: 'authentication',
          element: (
            <PrivateRoleRoute companyRoles={['internal']} path="authentication">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [{ index: true, element: <Authentication /> }],
        },
        {
          path: 'maintenance',
          element: (
            <PrivateRoleRoute companyRoles={['internal']} path="maintenance">
              <Outlet />
            </PrivateRoleRoute>
          ),
          children: [
            { index: true, element: <Maintenance /> },
            { path: 'create', element: <MaintenanceCreate /> },
            { path: ':maintenanceId', element: <MaintenanceDetails /> },
            { path: ':maintenanceId/edit', element: <MaintenanceEdit /> },
          ],
        },
        {
          path: 'logs',
          element: <Outlet />,
          children: [
            {
              path: 'construction-machine-logs',
              element: (
                <PrivateRoleRoute companyRoles={['internal']} path="*">
                  <Outlet />
                </PrivateRoleRoute>
              ),
              children: [
                { index: true, element: <ConstructionMachineLogs /> },
              ],
            },
            {
              path: 'sign-in-logs',
              element: (
                <PrivateRoleRoute companyRoles={['internal']} path="*">
                  <Outlet />
                </PrivateRoleRoute>
              ),
              children: [
                { index: true, element: <LogFiles /> }
              ],
            },
          ],
        },
      ],
    },
   
    {
      path: 'notifications',
      element: <Outlet />,
      children: [
        { index: true, element: <Notifications /> },
        { path: ':notificationId/edit', element: <EditNotification /> },
      ],
    },
  ],
};

export default ConfigurationADMsRoutes;
