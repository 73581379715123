import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import type { StackProps } from '@mui/material/Stack';
import Stack from '@mui/material/Stack';
import type { FC } from 'react';

interface Props {
  BoxProps?: BoxProps;
  StackProps?: StackProps;
}

const DialogFooter: FC<Props> = (props) => {
  const { children, BoxProps, StackProps } = props;
  return (
    <Box {...BoxProps}>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 3, py: 2 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          {...StackProps}
        >
          {children}
        </Stack>
      </Box>
    </Box>
  );
};

export default DialogFooter;
