import HttpClient from 'utils/HttpClient';
import type { CommonResponse } from 'types/common';

export interface Notification {
  createdDate: string;
  id: number;
  isRead: boolean;
  isSent: boolean;
  notificationContent: string | null;
  notificationTitle: string;
  toUser: string;
}

export interface FilterNotificationsParams {
  pageIndex: number;
  pageSize: number;
  status: boolean | null;
}

interface Notifications {
  notifies: Notification[];
  unread: number;
}

const getNotificationByUserId = async (params: FilterNotificationsParams) => {
  return HttpClient.post<typeof params, CommonResponse<Notifications>>(
    '/shared/Notification/GetNotificationByUserId',
    params
  );
};
const updateNotificationIsSent = async (listId: number[]) => {
  return HttpClient.post<typeof listId, CommonResponse>(
    '/shared/Notification/UpdateNotificationIsSent',
    listId
  );
};

export { getNotificationByUserId, updateNotificationIsSent };
