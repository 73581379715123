import Avatar from '@mui/material/Avatar';
import Image from 'components/Image';
import type { PlaceHolder } from 'constants/placeholderImages';
import { placeHolderSmallImages } from 'constants/placeholderImages';
import { getImageUrl } from 'utils/getImageUrl';

interface Props {
  src: string | null;
  type: PlaceHolder;
}

const TableAvatar = (props: Props) => {
  const { src, type } = props;

  const placeHolderImage = placeHolderSmallImages[type];

  return (
    <Avatar
      alt="Avatar"
      src={getImageUrl(src) || placeHolderImage}
      sx={{
        backgroundColor: 'revert',
        '& > img': {
          objectFit: 'contain',
        },
      }}
    >
      <Image alt="Place holder" src={placeHolderImage} sx={{ height: 1 }} />
    </Avatar>
  );
};

export default TableAvatar;
